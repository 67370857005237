import { Button, LegacyCard, TextField } from '@shopify/polaris';
import { memo } from 'react';
import { Enum } from '@/constants';
import { apiCaller } from '@/redux/query';
import { useDispatch, useSelector } from 'react-redux';
import blockListSlice, { settingSelector } from '@/redux/slice/blockList.slice';
import { validateIp } from '@/helpers';
import welcomeSlice from '@/redux/slice/welcome.slice';
import slice from '@/redux/slice';
const AddRule = (): JSX.Element => {
  const dispatch = useDispatch();
  const setting = useSelector(settingSelector);
  const [addBlackList] = apiCaller.useAddBlackListMutation();
  const [updateWelcomeStep] = apiCaller.useUpdateWelcomeStepMutation();
  const handleChangeValue = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, ipAddress: content },
      }),
    );
  };
  const handleDisableBtn = (): boolean => {
    const caseIpAddress = !setting.itemSelected.ipAddress || !validateIp(setting.itemSelected.ipAddress);
    return caseIpAddress;
  };
  const handleAddBlackList = () => {
    const saveBlackList = addBlackList;
    saveBlackList({
      priority: setting.itemSelected.priority,
      id: setting.itemSelected.id,
      criteria: Enum.CriteriaType.IpAddress,
      type: Enum.ActionType.Block,
      ipAddress: setting.itemSelected.ipAddress,
    })
    dispatch(welcomeSlice.actions.handleChangeWelcomeStep(3));
    updateWelcomeStep(3);
    dispatch(blockListSlice.actions.clearSetting());
  };

  return (
    <div>
      <LegacyCard>
        <LegacyCard.Section>
          <TextField
            label="Enter IP address"
            value={setting.itemSelected.ipAddress}
            onChange={handleChangeValue}
            autoComplete="off"
            helpText="Example of a valid IP address: 177.229.213.89"
            requiredIndicator
          />
        </LegacyCard.Section>
      </LegacyCard>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
        <Button
          disabled={handleDisableBtn()}
          size="medium"
          primary
          onClick={handleAddBlackList}
        >
          Save & Next
        </Button>
      </div>
    </div >
  );
};

export default memo(AddRule);
