import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ISettings } from '@/types/components';
import { Enum } from '@/constants';
import { HSBColor } from '@shopify/polaris';
import { images } from '@/asset';
// Define the initial state using that type
const initialState: {
  customTemplate: ISettings.CustomTemplate;
  customTemplateSaved: ISettings.CustomTemplate;
  titleColorInput: HSBColor;
  contentColorInput: HSBColor;
  backgroundColorInput: HSBColor;
} = {
  customTemplate: {
    title: 'Access Denied',
    content:
      'The site owner may have set restrictions that prevent you from accessing the site.Please contact the site owner for access.',
    image: images.blockify,
    backgroundImage: '',
    titleColor: {
      hue: 360,
      brightness: 0,
      saturation: 1,
    },
    contentColor: {
      hue: 360,
      brightness: 0,
      saturation: 1,
    },
    backgroundColor: {
      hue: 360,
      brightness: 1,
      saturation: 0,
    },
    backgroundType: Enum.BackgroundType.Color,
  },
  customTemplateSaved: {
    title: 'Access Denied',
    content:
      'The site owner may have set restrictions that prevent you from accessing the site.Please contact the site owner for access.',
    image: images.blockify,
    backgroundImage: '',
    titleColor: {
      hue: 360,
      brightness: 0,
      saturation: 1,
    },
    contentColor: {
      hue: 360,
      brightness: 0,
      saturation: 1,
    },
    backgroundColor: {
      hue: 360,
      brightness: 1,
      saturation: 0,
    },
    backgroundType: Enum.BackgroundType.Color,
  },
  titleColorInput: {
    hue: 360,
    brightness: 0,
    saturation: 1,
  },
  contentColorInput: {
    hue: 360,
    brightness: 0,
    saturation: 1,
  },
  backgroundColorInput: {
    hue: 360,
    brightness: 1,
    saturation: 0,
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTemplate: (state, action: PayloadAction<ISettings.CustomTemplate>) => {
      state.customTemplate = action.payload;
    },
    handleTemplateSaved: (state, action: PayloadAction<ISettings.CustomTemplate>) => {
      state.customTemplateSaved = action.payload;
    },
    handleTitleColor: (state, action: PayloadAction<HSBColor>) => {
      state.titleColorInput = action.payload;
    },
    handleContentColor: (state, action: PayloadAction<HSBColor>) => {
      state.contentColorInput = action.payload;
    },
    handleBackgroundColor: (state, action: PayloadAction<HSBColor>) => {
      state.backgroundColorInput = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const templateSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.customTemplate,
);
export const templateSavedSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.customTemplateSaved,
);

export const titleColorSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.titleColorInput,
);
export const contentColorSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.contentColorInput,
);

export const backgroundColorSelector = createSelector(
  (state: RootState) => state.setting,
  (state) => state.backgroundColorInput,
);

export default settingsSlice;
