/* eslint-disable jsx-a11y/img-redundant-alt */
import { Text } from '@shopify/polaris';
import AddRule from './AddRule';

const StepTwo = () => {
    return (
        <div className="step-two">
            <div className="welcome-title">
                <Text as="h3" variant="headingMd">
                    Add your first rule
                </Text>
            </div>
            <div className="panel-body">
                <AddRule />
            </div>
        </div>
    );
};

export default StepTwo;
