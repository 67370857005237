import styled from 'styled-components';
interface Props {
  maxWidth?: boolean;
}
export const LayoutStyled = styled.div<Props>`
  padding: 0 12px;
  padding-bottom: 32px;

  .Polaris-Frame__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .Polaris-Page {
      width: 100%;
      .Polaris-Page--divider {
        min-height: calc(100vh - 76px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 32px;
      }
    }
    .Polaris-Page > .Polaris-Box {
      position: sticky !important;
      top: 36px;
      z-index: 101;
      background-color: rgba(246, 246, 247, 1);
      padding: 20px 4px;
    }
    .Polaris-Page > .Polaris-Box ~ .Polaris-Box {
      position: relative;
    }
  }
  .nav-bar {
    position: sticky;
    top: 0;
    z-index: 100;
    width: calc(100vw - 20px);
  }
  .layout-footer {
    margin-top: 36px;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 1040px) {
    .Polaris-Page {
      min-width: 0;
      max-width: calc(100vw - 72px);
    }
  }
`;
