/* eslint-disable no-useless-escape */
import { HSBColor, hexToRgb, rgbToHsb } from '@shopify/polaris';
import { IToast } from '@/types/components';
import { Country } from 'country-state-city';

const ipv6Pattern =
    /(?:^|(?<=\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\s|$)/,
  ipv4StartPattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){1,4}$/;

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  return date;
};
export const getLastMonth = () => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};
export const getLast6Months = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 6);
  return date;
};

export const getLastYear = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export const renderCountry = (countryCode: string) => {
  const countries = [...otherCountry, ...listCountry()];
  const result = countries.find((item) => item.value === countryCode)?.label;
  return result;
};

export const validateIp = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    );
};

export const validateIPStartWith = (ipAddress: string): boolean => {
  if (!ipAddress) return false;
  if (ipv6Pattern.test(ipAddress)) return true;
  ipAddress = ipAddress.replace(/\.+$/, '');
  return ipv4StartPattern.test(ipAddress);
};

export function validateUrl(url: string) {
  const regex = /[(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
  const result = url.toLowerCase().match(regex);
  if (result === null) return false;
  else return true;
}

export const otherCountry = [
  {
    value: 'all',
    label: 'Add all countries',
  },
  {
    value: 'nonEu',
    label: 'Add all Non-EU countries',
  },
  {
    value: 'nonUs',
    label: 'Add all Non-US countries',
  },
];

export const listCountry = () => {
  const countries = Country.getAllCountries().map((item) => {
    return {
      label: item.name,
      value: item.isoCode,
    };
  });
  return [...countries];
};

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

export function deepObjectEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);

    if ((areObjects && !deepObjectEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hexToHSB = (color: string): HSBColor => {
  const rbgColor = hexToRgb(color);
  const hsbColor = rgbToHsb(rbgColor);
  return hsbColor;
};

export const handleToastMuatation = (res: any): IToast => {
  if ('data' in res) {
    if (res.data.state !== 1) {
      return {
        isOpen: true,
        content: res.data.msg || 'Failed',
        error: true,
      };
    } else {
      return {
        isOpen: true,
        content: res.data.msg || 'Saved',
        error: false,
      };
    }
  } else {
    return {
      isOpen: true,
      content: res.data.msg || 'something happened',
      error: false,
    };
  }
};

export const convertToCamelCase = (str: string): string =>
  str
    .replace(/"/g, '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/(\s|\b)(\w)/g, (m, space, chr) => chr.toLowerCase());
