import { Badge, Button } from "@shopify/polaris"
import { ISettingToggle } from '@/types/components'
import { memo } from "react"
import { CustomSettingToggleStyled } from "./styled"

const CustomSettingToggle = ({ ...props }: ISettingToggle.IProps) => {
  return (
    <CustomSettingToggleStyled>
      <strong style={{ display: 'block' }}>
        {props.title}
        <Badge progress='complete' status={props.enabled ? 'success' : 'critical'}>
          {props.enabled ? 'On' : 'Off'}
        </Badge>
      </strong>
      <Button
        onClick={props.handleToggle}
        loading={props.loading}
        primary={props.enabled ? false : true}
      >
        {props.enabled ? 'Turn off' : 'Turn on'}
      </Button>
    </CustomSettingToggleStyled>
  )
}

export default memo(CustomSettingToggle)
