import styled from 'styled-components';

export const DashboardStyled = styled.div`
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
    .Polaris-ResourceList {
      min-width: 850px;
    }
  }
  .mt-16 {
    margin-top: 16px;
  }
  .rsm-svg {
    width: fit-content;
    height: fit-content;
  }
  .table-content {
    justify-content: space-between;
  }
  .header {
    .Polaris-Icon {
      margin: 0% 8px;
    }
  }
  nav {
    display: flex;
    justify-content: center;
  }
`;
