import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import { config } from '@/config';
const endPoint = process.env.REACT_APP_API_END_POINT;
const instanceId = config.instanceId;
// const params = {
//   shop: config.shop,
//   urlParams: config.urlParams,
// };

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: endPoint,
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', `*`);
      headers.set('Authorization', `${config.instance ? config.instance : ''}`);
      // }
      return headers;
    },
  }),
  tagTypes: ['blocklist', 'general', 'ipAccess'],
  endpoints: (builder) => ({
    getGeneralDetail: builder.query<IResponseApi.GeneralDetail, string | undefined>({
      query: (instanceId) => {
        return {
          url: `setting/general-detail`,
          method: 'GET',
          params: {
            instanceId: instanceId,
          },
        };
      },
      providesTags: [{ type: 'general' }],
    }),

    activeProxyVpn: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: (input) => {
        return {
          url: `setting/proxy-vpn/active`,
          method: 'PUT',
          body: {
            instanceId,
            status: input,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeContentProtection: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/protect-text-image`,
          method: 'PUT',
          body: {
            instanceId,
            protectContents: input.protectContents,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeBehavior: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/behavior`,
          method: 'PUT',
          body: {
            instanceId,
            disableRightClick: input.disableRightClick,
            disableKeyboardShortcut: input.disableKeyboardShortcut,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeAutoBlock: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/auto-block`,
          method: 'PUT',
          body: {
            instanceId,
            autoBlock: input.autoBlock,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    activeTorBlock: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IContentProtection>({
      query: (input) => {
        return {
          url: `setting/tor-block`,
          method: 'PUT',
          body: {
            instanceId,
            torBlock: input.torBlock,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    fetchSettingList: builder.query<IResponseApi.SettingList, IParamsApi.IGetSettingList>({
      query: (input) => {
        return {
          url: 'setting/list',
          method: 'GET',
          params: { instanceId, ...input },
        };
      },
      providesTags: [{ type: 'blocklist' }],
    }),
    addBlackList: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ISaveBlackList>({
      query: (input) => {
        return {
          url: 'setting/rule/black-list',
          method: 'POST',
          body: { instanceId, ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }, { type: 'ipAccess' }],
    }),

    addWhiteList: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ISaveWhiteList>({
      query: (input) => {
        return {
          url: 'setting/rule/white-list',
          method: 'POST',
          body: { instanceId, ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    deleteSetting: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (input) => {
        return {
          url: `setting/rule/${input}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),
    deleteAllBlackListSetting: builder.mutation<IResponseApi.CommonResponse, undefined>({
      query: () => {
        return {
          url: `setting/rules/black-list`,
          method: 'DELETE',
          body: { instanceId },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),
    deleteAllWhiteListSetting: builder.mutation<IResponseApi.CommonResponse, undefined>({
      query: () => {
        return {
          url: `setting/rules/white-list`,
          method: 'DELETE',
          body: { instanceId },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    updateSetting: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IUpdateRule>({
      query: (input) => {
        return {
          url: `setting/rule/${input.id}`,
          method: 'PUT',
          body: { instanceId, ...input },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    uploadImage: builder.mutation<IResponseApi.IUploadImage, FormData>({
      query: (image) => {
        return {
          url: `file/upload-image`,
          method: 'POST',
          body: image,
        };
      },
    }),

    updateTemplate: builder.mutation<IResponseApi.IUploadImage, IParamsApi.IUpdateCustomTemplate>({
      query: (input) => {
        return {
          url: `setting/general/block-template`,
          method: 'PUT',
          body: {
            ...input,
            instanceId,
          },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    fetchVisitorList: builder.query<IResponseApi.VisitorList, IParamsApi.IGetVisitorLog>({
      query: (input) => {
        return {
          url: `visitor/list`,
          method: 'GET',
          params: { instanceId, ...input },
        };
      },
      providesTags: [{ type: 'ipAccess' }],
    }),

    fetchChartAnalytics: builder.query<IResponseApi.VisitorChart, IParamsApi.IGetChart>({
      query: (startDate) => {
        return {
          url: `visitor/analytics`,
          method: 'GET',
          params: { instanceId, ...startDate },
        };
      },
    }),

    fetchVisitorBlockList: builder.query<IResponseApi.VisitorList, IParamsApi.IGetVisitorLog>({
      query: (input) => {
        return {
          url: `visitor/block/list`,
          method: 'GET',
          params: { instanceId, ...input },
        };
      },
      providesTags: [{ type: 'ipAccess' }],
    }),

    fetchChartBlock: builder.query<IResponseApi.BlockChart, IParamsApi.IGetChart>({
      query: (startDate) => {
        return {
          url: `visitor/block/analytics`,
          method: 'GET',
          params: { instanceId, ...startDate },
        };
      },
    }),

    handleCsv: builder.mutation<IResponseApi.IUploadCsv, IParamsApi.IUploadCSV>({
      query: (input) => {
        return {
          url: `setting/import`,
          method: 'POST',
          body: { instanceId, data: input.data },
        };
      },
      invalidatesTags: [{ type: 'blocklist' }],
    }),

    downloadTemplate: builder.query<IResponseApi.IExport, undefined>({
      query: () => {
        return {
          url: `setting/import-template`,
          method: 'GET',
          params: { instanceId },
        };
      },
    }),

    updateWelcomeStep: builder.mutation<IResponseApi.CommonResponse, number>({
      query: (step) => {
        return {
          url: `setting/welcome-step/update`,
          method: 'PUT',
          body: { instanceId, step },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    getListISP: builder.query<IResponseApi.IListIsp, IParamsApi.IListIsp>({
      query: (input) => {
        return {
          url: `isp/list`,
          method: 'GET',
          params: { countryCode: input.countryCode, search: input.search },
        };
      },
    }),

    updateReview: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: (status) => {
        return {
          url: `setting/review-status/update`,
          method: 'PUT',
          body: { instanceId, status },
        };
      },
    }),

    createReview: builder.mutation<IResponseApi.CommonResponse, IParamsApi.IReview>({
      query: (input) => {
        return {
          url: `review/create`,
          method: 'POST',
          body: { instanceId, type: input.type, content: input.content },
        };
      },
    }),

    getAdminURL: builder.query<IResponseApi.IGetAdminURL, any>({
      query: () => {
        return {
          url: 'setting/site-access',
          method: 'GET',
          params: { instanceId },
        };
      },
    }),

    enableApp: builder.mutation<IResponseApi.CommonResponse, boolean>({
      query: () => {
        return {
          url: 'setting/enable-app',
          method: 'PUT',
          params: { instanceId },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),

    settingTimezone: builder.mutation<IResponseApi.CommonResponse, IParamsApi.ITimezone>({
      query: (input) => {
        return {
          url: 'setting/timezone',
          method: 'PUT',
          body: input,
          params: { instanceId },
        };
      },
      invalidatesTags: [{ type: 'general' }],
    }),
  }),
});
