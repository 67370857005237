import styled from 'styled-components';

export const AnalyticsStyled = styled.div`
   .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
    .Polaris-ResourceList {
      min-width: 1250px;
    }
    .Polaris-ResourceList__FiltersWrapper {
      min-width: 1250px;
    }
  }
  .Polaris-LegacyCard {
    margin-top: 0px;
  }
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
  }
  .d-flex {
    display: flex;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .table-content {
    justify-content: space-between;
  }
  .header {
    .Polaris-Icon {
      margin: 0% 8px;
    }
  }
  nav {
    display: flex;
    justify-content: center;
  }
  @media only screen and (min-width: 1040px) {
    .Polaris-Filters {
      max-width: 75%;
    }
  }
  @media only screen and (min-width: 1220px) {
    .Polaris-Filters {
      min-width: 90%;
    }
  }
  @media only screen and (min-width: 1353px) {
    .Polaris-Filters {
      min-width: 100%;
    }
  }
`;
