/* eslint-disable react-hooks/exhaustive-deps */
import { LINK, PATH } from '@/constants';
import { ILayout } from '@/types/components';
import { Button, ButtonGroup, EmptyState, Frame, LegacyCard, Link, Loading, Page, Text } from '@shopify/polaris';
import { HomeMajor, SettingsMajor, ToolsMajor, AnalyticsMajor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutStyled } from './styled';
import Toast from '../common/Toast';
import { apiCaller } from '@/redux/query';
import { config } from '@/config';
import { images } from '@/asset';

const btnGroup = [
  {
    content: 'Dashboard',
    icon: HomeMajor,
    path: PATH.DASHBOARD,
  },
  {
    content: 'Blocker & Re-director ',
    icon: ToolsMajor,
    path: PATH.BLOCKLIST,
  },
  {
    content: 'Visitor Analytics',
    icon: AnalyticsMajor,
    path: PATH.ANALYTICS,
  },
  {
    content: 'Settings',
    icon: SettingsMajor,
    path: PATH.SETTINGS,
  },
];

const Layout = ({ children, layoutProps, isVisibleHeader = true }: ILayout.IProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const settings = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const handleOpenPublishSite = () => {
    window.open(settings.data?.url);
  };
  return (
    <LayoutStyled>
      <Frame>
        <Toast />
        {isVisibleHeader && !settings?.data?.url ? (
          <div className="nav-bar">
            <ButtonGroup fullWidth segmented>
              {btnGroup.map((item, index) => {
                return (
                  <Button
                    key={index}
                    icon={item.icon}
                    primary={location.pathname === item.path.pathname || (index === 0 && location.pathname === '/')}
                    onClick={() => {
                      navigate({
                        ...item.path,
                      });
                    }}
                  >
                    {item.content}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        ) : null}
        {settings?.isLoading ? <Loading /> : null}
        {settings.data?.url ? (
          <div style={{ width: '100%' }}>
            <LegacyCard sectioned>
              <EmptyState
                heading="Oh no! It seems like your site is not published!"
                action={{ content: 'Publish from Wix site editor', onAction: handleOpenPublishSite }}
                image={images.publishSite}
              >
                <p>Please go to Wix's site editor and publish your site to use Blockify.</p>
              </EmptyState>
            </LegacyCard>
          </div>
        ) : (
          <Page {...layoutProps} divider>
            {children}
            <div className="layout-footer">
              <Text as="h6" variant="bodyMd">
                Share your experience with Blockify here{' '}
                <Link external url={LINK.wixReview}>
                  leave a review
                </Link>
              </Text>
            </div>
          </Page>
        )}
      </Frame>
    </LayoutStyled>
  );
};
export default memo(Layout);
