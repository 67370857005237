import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiCaller } from './query';
import { blockListSlice } from './slice/blockList.slice';
import { dashboardSlice } from './slice/dashboard.slice';
import { toastSlice } from './slice/toast.slice';
import { visitorLogSlice } from './slice/visitorAnalytics.slice';
import { welcomeSlice } from './slice/welcome.slice';
import settingsSlice from './slice/settings.slice';

const toastPersistConfig = {
  key: 'toast',
  storage,
};

const welcomePersistConfig = {
  key: 'welcome',
  storage,
};

const rootReducer = combineReducers({
  // Thêm Reducer tại đây. Sample : sample : sampleReducer
  blockList: blockListSlice.reducer,
  dashboard: dashboardSlice.reducer,
  setting: settingsSlice.reducer,
  apiCaller: apiCaller.reducer,
  toast: persistReducer(toastPersistConfig, toastSlice.reducer),
  visitorLog: visitorLogSlice.reducer,
  welcome: persistReducer(welcomePersistConfig, welcomeSlice.reducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const customizedMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return customizedMiddleware.concat(apiCaller.middleware);
  },
});

// setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
