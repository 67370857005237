import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IParamsApi } from '@/types/api/params.api';
import { getSomeDaysAgo } from '@/helpers';
import { Enum } from '@/constants';

const now = new Date();
// Define the initial state using that type
const initialState: { visitorLogTable: IParamsApi.IGetVisitorLog; inputSearch: string; chartAnalytics: IParamsApi.IGetChart, titleBtnDatePicker: string } = {
  visitorLogTable: {
    search: '',
    status: '',
    risk: '',
    type: '',
    startDate: getSomeDaysAgo(7),
    endDate: now,
    perPage: '10',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'lastVisitedTime',
  },
  chartAnalytics: {
    startDate: getSomeDaysAgo(7),
    endDate: now,
  },
  inputSearch: '',
  titleBtnDatePicker: 'Last 7 days',
};

export const visitorLogSlice = createSlice({
  name: 'visitorLog',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleVisitorLogTable: (state, action: PayloadAction<IParamsApi.IGetVisitorLog>) => {
      state.visitorLogTable = action.payload;
    },
    handlePeriodTable: (
      state,
      action: PayloadAction<{
        startTime: Date;
        endTime: Date;
      }>,
    ) => {
      state.visitorLogTable.startDate = action.payload.startTime;
      state.chartAnalytics.startDate = action.payload.startTime;
      state.visitorLogTable.endDate = action.payload.endTime;
      state.chartAnalytics.endDate = action.payload.endTime;
    },
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    chartAnalytics: (state, action: PayloadAction<IParamsApi.IGetChart>) => {
      state.chartAnalytics = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const visitorLogSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.visitorLogTable,
);
export const inputSearchSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.inputSearch,
);
export const periodSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => {
    return {
      startTime: state.visitorLogTable.startDate && state.chartAnalytics.startDate,
      endTime: state.visitorLogTable.endDate && state.chartAnalytics.endDate,
    };
  },
);
export const chartAnalyticsSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.chartAnalytics,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.visitorLog,
  (state) => state.titleBtnDatePicker,
);

export default visitorLogSlice;
