/* eslint-disable react-hooks/exhaustive-deps */
import CustomLayout from '@/components/layout';
import { Enum, PATH } from '@/constants';
import {
  Badge,
  FullscreenBar,
  Text,
  ButtonGroup,
  Button,
  Layout,
  LegacyCard,
  TextField,
  Columns,
  ColorPicker,
  HSBAColor,
  ChoiceList,
  hsbToHex,
} from '@shopify/polaris';
import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { FullScreenStyled, TemplateFullScreenStyled } from '@/pages/settings/styled';
import CustomImage from './CustomImage';
import { useDispatch, useSelector } from 'react-redux';
import settingsSlice, {
  backgroundColorSelector,
  contentColorSelector,
  templateSavedSelector,
  templateSelector,
  titleColorSelector,
} from '@/redux/slice/settings.slice';
import { ISettings } from '@/types/components';
import { deepObjectEqual, handleToastMuatation, hexToHSB } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { config } from '@/config';
import toastSlice from '@/redux/slice/toast.slice';
const btnGroup = [
  {
    icon: DesktopMajor,
    fontSize: '16px',
  },
  {
    icon: MobileMajor,
    fontSize: '10px',
  },
];
const FullscreenBarMarkup = () => {
  const dispatch = useDispatch();
  const template = useSelector(templateSelector);
  const templateSaved = useSelector(templateSavedSelector);
  const titleColor = useSelector(titleColorSelector);
  const contentColor = useSelector(contentColorSelector);
  const backgroundColor = useSelector(backgroundColorSelector);
  const [updateTemplate, updateTemplateStatus] = apiCaller.useUpdateTemplateMutation();
  const [uploadImage, uploadImageStatus] = apiCaller.useUploadImageMutation();
  const generalDetail = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const navigate = useNavigate();
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [files, setFiles] = useState<{ image: null | File; backgroundImage: null | File }>({
    image: null,
    backgroundImage: null,
  });

  useEffect(() => {
    if (generalDetail.data && generalDetail.data.settings.blockTemplate) {
      const settings = generalDetail.data.settings.blockTemplate;
      const template: ISettings.CustomTemplate = {
        backgroundColor:
          settings.backgroundType === Enum.BackgroundType.Color ? hexToHSB(settings.background) : hexToHSB('#ffffff'),
        backgroundImage: settings.backgroundType === Enum.BackgroundType.Image ? settings.background : '',
        backgroundType: settings.backgroundType,
        content: settings.description,
        contentColor: hexToHSB(settings.descriptionColor),
        image: settings.logoImage,
        title: settings.title,
        titleColor: hexToHSB(settings.titleColor),
      };
      dispatch(settingsSlice.actions.handleTemplate({ ...template }));
      dispatch(settingsSlice.actions.handleTemplateSaved({ ...template }));
      if (settings.backgroundType === Enum.BackgroundType.Color) {
        dispatch(settingsSlice.actions.handleBackgroundColor(hexToHSB(settings.background)));
      } else {
        dispatch(settingsSlice.actions.handleBackgroundColor(hexToHSB('#ffffff')));
      }
      dispatch(settingsSlice.actions.handleContentColor(hexToHSB(settings.descriptionColor)));
      dispatch(settingsSlice.actions.handleTitleColor(hexToHSB(settings.titleColor)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalDetail?.data?.settings.blockTemplate]);

  const handleButtonClick = useCallback(
    (index: number) => {
      if (activeButtonIndex === index) return;
      setActiveButtonIndex(index);
    },
    [activeButtonIndex],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleChange = useCallback(
    _debounce((value: ISettings.CustomTemplate) => {
      dispatch(settingsSlice.actions.handleTemplate(value));
    }, 1000),
    [],
  );

  const handleOnChange = (key: 'title' | 'content') => (value: string | HSBAColor) => {
    dispatch(
      settingsSlice.actions.handleTemplate({
        ...template,
        [key]: value,
      }),
    );
  };
  const handleUpdateTemplate = async () => {
    try {
      const imageFormData = new FormData();
      const backgroundImageFormData = new FormData();
      imageFormData.append('image', files.image || '');
      backgroundImageFormData.append('image', files.backgroundImage || '');
      const image = files.image && (await uploadImage(imageFormData));
      const backgroundImage = files.backgroundImage && (await uploadImage(backgroundImageFormData));
      const imageUrl = image && 'data' in image ? image.data.url : '';
      const backgroundImageUrl = backgroundImage && 'data' in backgroundImage ? backgroundImage.data.url : '';
      updateTemplate({
        background:
          template.backgroundType === Enum.BackgroundType.Color
            ? hsbToHex(template.backgroundColor)
            : files.backgroundImage
            ? backgroundImageUrl
            : template.backgroundImage,
        backgroundType: template.backgroundType,
        description: template.content,
        descriptionColor: hsbToHex(template.contentColor),
        logoImage: files.image ? imageUrl : template.image,
        title: template.title,
        titleColor: hsbToHex(template.titleColor),
      }).then((res) => {
        dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
        dispatch(
          settingsSlice.actions.handleTemplateSaved({
            ...template,
          }),
        );
      });
    } catch {
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: 'Save failure',
          error: false,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(toastSlice.actions.hideToast());
    };
  }, []);

  return (
    <CustomLayout isVisibleHeader={false} layoutProps={{ fullWidth: true }}>
      <FullscreenBar onAction={() => navigate(PATH.SETTINGS)}>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <Badge progress="complete" status={deepObjectEqual(template, templateSaved) ? 'success' : 'attention'}>
            {deepObjectEqual(template, templateSaved) ? 'Saved' : 'Edited'}
          </Badge>
          <div style={{ marginLeft: '1rem', flexGrow: 1 }}>
            <Text variant="headingLg" as="p">
              Blocking template editor
            </Text>
          </div>
          <ButtonGroup>
            <Button
              disabled={deepObjectEqual(template, templateSaved)}
              onClick={() => {
                dispatch(settingsSlice.actions.handleTitleColor(templateSaved.titleColor));
                dispatch(settingsSlice.actions.handleBackgroundColor(templateSaved.backgroundColor));
                dispatch(settingsSlice.actions.handleContentColor(templateSaved.contentColor));
                dispatch(
                  settingsSlice.actions.handleTemplate({
                    ...templateSaved,
                  }),
                );
              }}
            >
              Reset
            </Button>
            <Button
              loading={uploadImageStatus.isLoading || updateTemplateStatus.isLoading}
              disabled={deepObjectEqual(template, templateSaved)}
              primary
              onClick={handleUpdateTemplate}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </FullscreenBar>
      <FullScreenStyled>
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <div className="d-flex flex-end">
                <ButtonGroup segmented>
                  {btnGroup.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        icon={item.icon}
                        primary={activeButtonIndex === index}
                        onClick={() => handleButtonClick(index)}
                      />
                    );
                  })}
                </ButtonGroup>
              </div>
              <TemplateFullScreenStyled
                template={{
                  backgroundColor: hsbToHex(template.backgroundColor),
                  contentColor: hsbToHex(template.contentColor),
                  titleColor: hsbToHex(template.titleColor),
                  backgroundImage: template.backgroundImage,
                  backgroundType: template.backgroundType,
                }}
                fontSize={btnGroup[activeButtonIndex].fontSize}
              >
                <div className="full-template-container">
                  <img className="block-img" src={template.image} alt="logo" />
                  <div className="mt-6">
                    <div className="fz-3-75 break-line">{template.title}</div>
                  </div>
                  <div className="mt-2-5 template-content break-line">
                    <div className="fz-1-5 content">{template.content}</div>
                  </div>
                </div>
              </TemplateFullScreenStyled>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section secondary>
            <LegacyCard title="Custom template" sectioned>
              <TextField
                maxLength={64}
                label="Title"
                value={template.title}
                onChange={handleOnChange('title')}
                autoComplete="off"
              />
              <TextField
                maxLength={256}
                label="Content"
                value={template.content}
                onChange={handleOnChange('content')}
                autoComplete="off"
              />
              <div className="mt-16">
                <Columns columns={{ xl: 2, lg: 2, md: 2, xs: 2, sm: 2 }} gap={{ xl: '4', lg: '4', md: '4', sm: '4', xs: '4' }}>
                  <Text as="h6" variant="bodyMd">
                    Title color:
                    <ColorPicker
                      onChange={(value) => {
                        dispatch(settingsSlice.actions.handleTitleColor(value));
                        debounceHandleChange({
                          ...template,
                          titleColor: value,
                        });
                      }}
                      color={titleColor}
                    />
                  </Text>
                  <Text as="h6" variant="bodyMd">
                    Content color:
                    <ColorPicker
                      onChange={(value) => {
                        dispatch(settingsSlice.actions.handleContentColor(value));
                        debounceHandleChange({
                          ...template,
                          contentColor: value,
                        });
                      }}
                      color={contentColor}
                    />
                  </Text>
                </Columns>
              </div>
              <div className="mt-16">
                <Text as="h6" variant="bodyMd">
                  Upload image:
                  <CustomImage
                    handleUpload={(file: File, src: string) => {
                      setFiles({
                        ...files,
                        image: file,
                      });
                      dispatch(
                        settingsSlice.actions.handleTemplate({
                          ...template,
                          image: src,
                        }),
                      );
                    }}
                  />
                </Text>
              </div>
              <ChoiceList
                title="Background type"
                choices={[
                  { label: 'Background Color', value: Enum.BackgroundType.Color },
                  { label: 'Background Image', value: Enum.BackgroundType.Image },
                ]}
                selected={[template.backgroundType]}
                onChange={(value) => {
                  dispatch(
                    settingsSlice.actions.handleTemplate({
                      ...template,
                      backgroundType: value[0],
                    }),
                  );
                }}
              />
              {template.backgroundType === Enum.BackgroundType.Image ? (
                <div className="mt-16">
                  <Text as="h6" variant="bodyMd">
                    Upload background image:
                    <CustomImage
                      handleUpload={(file: File, src: string) => {
                        setFiles({
                          ...files,
                          backgroundImage: file,
                        });
                        dispatch(
                          settingsSlice.actions.handleTemplate({
                            ...template,
                            backgroundImage: src,
                          }),
                        );
                      }}
                    />
                  </Text>
                </div>
              ) : (
                <div className="mt-16">
                  <Text as="h6" variant="bodyMd">
                    Background Color
                    <ColorPicker
                      fullWidth
                      onChange={(value) => {
                        dispatch(settingsSlice.actions.handleBackgroundColor(value));
                        debounceHandleChange({
                          ...template,
                          backgroundColor: value,
                        });
                      }}
                      color={backgroundColor}
                    />
                  </Text>
                </div>
              )}
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </FullScreenStyled>
    </CustomLayout>
  );
};

export default memo(FullscreenBarMarkup);
