import { images } from '@/asset';
import { Enum } from '@/constants';
import { CriteriaType } from './enum';

export const perPageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

const typeList = [
  {
    label: 'Black list',
    value: Enum.ListType.BlackList,
  },
  {
    label: 'White list',
    value: Enum.ListType.WhiteList,
  },
];

const criteriaOptions = (plan: string) => {
  return [
    {
      label: 'IP Address',
      value: Enum.CriteriaType.IpAddress,
    },
    {
      label: 'IP Address start with',
      value: Enum.CriteriaType.IpAddressStartWith,
    },
    {
      label: 'Country',
      value: Enum.CriteriaType.Country,
    },
    {
      label: 'State/Province',
      value: Enum.CriteriaType.Province,
    },
    {
      label:
        plan === Enum.UserPlan.FREE || plan === Enum.UserPlan.BASIC
          ? 'Internet Service Provider (ISP) (Available on Advance plan or above)'
          : 'Internet Service Provider (ISP)',
      value: Enum.CriteriaType.ISP,
      disabled: plan === Enum.UserPlan.FREE || plan === Enum.UserPlan.BASIC,
    },
  ];
};

const criteriaFilters = [
  {
    label: 'IP Address',
    value: Enum.CriteriaType.IpAddress,
  },
  {
    label: 'IP Address start with',
    value: Enum.CriteriaType.IpAddressStartWith,
  },
  {
    label: 'Country',
    value: Enum.CriteriaType.Country,
  },
  {
    label: 'State/Province',
    value: Enum.CriteriaType.Province,
  },
  {
    label: 'Internet Service Provider (ISP)',
    value: Enum.CriteriaType.ISP,
  },
];

const blockTypeOptions = [
  {
    label: 'Block',
    value: Enum.ActionType.Block,
  },
  {
    label: 'Redirect',
    value: Enum.ActionType.Redirect,
  },
];

const listTypeOptions = [
  {
    label: 'Block',
    value: Enum.ActionType.Block,
  },
  {
    label: 'Redirect',
    value: Enum.ActionType.Redirect,
  },
  {
    label: 'Whitelist',
    value: '0',
  },
];

const configRules: Array<{
  id: CriteriaType;
  description: string;
  src: string;
  condition?: Array<string>;
  tooltip?: string;
}> = [
  {
    id: Enum.CriteriaType.IpAddress,
    description: 'IP address',
    src: images.block_ip_address,
  },
  {
    id: Enum.CriteriaType.IpAddressStartWith,
    description: 'IP start with',
    src: images.block_ip_range,
  },
  {
    id: Enum.CriteriaType.Country,
    description: 'country',
    src: images.block_country,
  },
  {
    id: Enum.CriteriaType.Province,
    description: 'state',
    src: images.block_state,
  },
  {
    id: Enum.CriteriaType.ISP,
    description: 'ISP',
    src: images.block_isp,
    condition: [Enum.UserPlan.FREE, Enum.UserPlan.BASIC],
    tooltip: 'Available on advance plan or above!',
  },
];

export default {
  configRules,
  criteriaFilters,
  criteriaOptions,
  blockTypeOptions,
  typeList,
  perPageOptions,
  listTypeOptions,
};
