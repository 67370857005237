import React from 'react';
import { LegacyStack, Modal, ModalProps, Select, Text, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import blockListSlice, { selectTypeSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { State } from 'country-state-city';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { apiCaller } from '@/redux/query';
import { Enum } from '@/constants';
import slice from '@/redux/slice';
import { handleToastMuatation, listCountry, validateUrl } from '@/helpers';

function ModalBlockState({ ...props }: ModalProps) {
  const dispatch = useDispatch();
  const typeRule = useSelector(selectTypeSelector);
  const setting = useSelector(settingSelector);
  const [addBlackList, addBlackListStatus] = apiCaller.useAddBlackListMutation();
  const [addWhiteList, addWhiteListStatus] = apiCaller.useAddWhiteListMutation();
  const listProvince = (country: string) => {
    const province = State?.getStatesOfCountry(country).map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });
    const listProvince = province.filter((item) => item.label !== 'Taiwan' && item.label !== 'Hong Kong SAR')
    return listProvince;
  };

  const handleChangeRedirect = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, linkRedirect: content },
      }),
    );
  };
  const handleChangeDisplayName = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({ ...setting, itemSelected: { ...setting.itemSelected, shortUrl: content } }),
    );
  };
  const handleChangeValueCountry = (value: string) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: {
          ...setting.itemSelected,
          city: [],
          country: [value],
        },
      }),
    );
  };

  const handleClearAll = () => {
    dispatch(blockListSlice.actions.clearSetting());
  };
  const handleDisableBtn = (): boolean => {
    const caseProvince = setting?.itemSelected?.city?.length === 0;
    const caseRedirect =
      typeRule === Enum.ActionType.Redirect &&
      (!setting.itemSelected.linkRedirect ||
        (!!setting.itemSelected.linkRedirect && !validateUrl(setting.itemSelected.linkRedirect)));
    return caseProvince || caseRedirect;
  };
  const handleAddBlackList = () => {
    const saveBlackList = addBlackList;
    if (typeRule === Enum.ActionType.Redirect) {
      saveBlackList({
        priority: setting.itemSelected.priority,
        id: setting.itemSelected.id,
        criteria: Enum.CriteriaType.Province,
        type: Enum.ActionType.Redirect,
        ipAddress: setting.itemSelected.ipAddress,
        linkRedirect: setting.itemSelected.linkRedirect,
        shortUrl: setting.itemSelected.shortUrl,
        country: setting.itemSelected.country,
        city: setting.itemSelected.city,
      }).then((res) => {
        props.onClose();
        dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      });
    } else {
      saveBlackList({
        priority: setting.itemSelected.priority,
        id: setting.itemSelected.id,
        criteria: Enum.CriteriaType.Province,
        type: Enum.ActionType.Block,
        country: setting.itemSelected.country,
        city: setting.itemSelected.city,
      }).then((res) => {
        props.onClose();
        dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      });
    }
    dispatch(blockListSlice.actions.clearSetting());
  };
  const handleAddWhiteList = () => {
    const saveWhiteList = addWhiteList;
    saveWhiteList({
      id: setting.itemSelected.id,
      note: setting.itemSelected.note,
      priority: setting.itemSelected.priority,
      criteria: Enum.CriteriaType.Province,
      country: setting.itemSelected.country,
      city: setting.itemSelected.city,
    }).then((res) => {
      props.onClose();
      dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
    });
    dispatch(blockListSlice.actions.clearSetting());
  };

  return (
    <div>
      <Modal
        {...props}
        large
        open={props.open}
        onClose={props.onClose}
        title={
          <div>
            {typeRule === Enum.ActionType.Block ? 'Add state/province to block' : null}
            {typeRule === Enum.ActionType.Redirect ? 'Add state/province to redirect' : null}
            {typeRule === '0' ? 'Add state/province to whitelist' : null}
          </div>
        }
        primaryAction={{
          content: 'Add',
          onAction: typeRule === '0' ? handleAddWhiteList : handleAddBlackList,
          disabled: handleDisableBtn(),
          loading: addBlackListStatus.isLoading || addWhiteListStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Clear All',
            onAction: handleClearAll,
          },
        ]}
      >
        <Modal.Section>
          {typeRule === Enum.ActionType.Redirect ? (
            <LegacyStack vertical spacing="baseTight">
              <TextField
                label="Redirect to"
                value={setting.itemSelected.linkRedirect}
                onChange={handleChangeRedirect}
                autoComplete="off"
                prefix="https://"
                requiredIndicator
              />
              {!!setting.itemSelected.linkRedirect && !validateUrl(setting.itemSelected.linkRedirect) ? (
                <Text as={'h6'} variant={'headingXs'} color="critical">
                  Please enter a valid URL
                </Text>
              ) : null}
              <TextField
                label="Displayed name (optional)"
                maxLength={32}
                value={setting.itemSelected.shortUrl}
                onChange={handleChangeDisplayName}
                autoComplete="off"
                helpText="This name is displayed instead of the full URL to save space."
              />
              <Select
                label="Country"
                options={listCountry()}
                onChange={handleChangeValueCountry}
                value={setting.itemSelected.country[0]}
                requiredIndicator
                placeholder="Pick a country"
              />
              <AutoCompleteWithTag
                options={listProvince(setting.itemSelected.country[0])}
                label="State/Province"
                selectedOptions={setting.itemSelected.city}
                setSelectedOptions={(selected: string[]) => {
                  dispatch(
                    blockListSlice.actions.handleSetting({
                      ...setting,
                      itemSelected: {
                        ...setting.itemSelected,
                        city: selected,
                      },
                    }),
                  );
                }}
                allowMultiple={true}
                requiredIndicator
              />
            </LegacyStack>
          ) : (
            <LegacyStack vertical spacing="baseTight">
              <Select
                label="Country"
                options={listCountry()}
                onChange={handleChangeValueCountry}
                value={setting.itemSelected.country[0]}
                requiredIndicator
                placeholder="Pick a country"
              />
              <AutoCompleteWithTag
                options={listProvince(setting.itemSelected.country[0])}
                label="State/Province"
                selectedOptions={setting.itemSelected.city}
                setSelectedOptions={(selected: string[]) => {
                  dispatch(
                    blockListSlice.actions.handleSetting({
                      ...setting,
                      itemSelected: {
                        ...setting.itemSelected,
                        city: selected,
                      },
                    }),
                  );
                }}
                allowMultiple={true}
                requiredIndicator
              />
            </LegacyStack>
          )}
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default ModalBlockState;
