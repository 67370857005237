import styled from "styled-components";

export const WelcomeStyled = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px;
    .Polaris-Box {
        max-width: 700px;
        width: 700px;
    }
    .welcome-container {
        ul {
            padding : 0;
            display: flex;
            justify-content: center;
            position: relative;
            margin-bottom: 35px;
            .welcome-bridge {
                position: absolute;
                top : 20px;
                left: 50px;
                width: 555px;
                background-color: #ebebeb;
                height: 10px;
                z-index: 1;
            }
            li {
                width: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .step-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                }
                .step-number {
                    text-align: center;
                    line-height: 50px;
                    width: 50px;
                    height: 50px;
                    color: #525252;
                    border-radius: 25px;
                    background-color: #ebebeb;
                    z-index: 2;
                    font-weight: 700;
                    user-select: none;
                    transition: all .3s ease-in-out;
                }
                .Polaris-Text--root {
                    text-align: center;
                    margin-top: 20px;
                    color : #ababab;
                }
                .Polaris-Text--root:hover {
                    text-decoration: underline;
                }
            }
            .active {
                .step-number {
                    line-height: 40px;
                    border: 5px solid #ebebeb;
                    background-color: #fff;
                    color : #525252;
                    z-index: 2;
                }
                .Polaris-Text--root {
                    color : #303641
                }
            }
            .completed {
                .step-number {
                    line-height: 40px;
                    background-color: #008060;
                    border: 5px solid #008060;
                    color : white;
                    z-index: 2;
                }
                .Polaris-Text--root {
                    color : #008060
                }

            }
        }
    }
    .step-two {
        .connect-to-paypal {
    padding: 0.625em 2em;
    max-width: 375px;
    font-size: 16px;
    background-image: -webkit-linear-gradient(#0070ba 20%, #0070ba) !important;
    background-image: linear-gradient(#0070ba 20%, #0070ba) !important;
    background-repeat: no-repeat !important;
    border-color: #0070ba;
    position: relative;
    white-space: nowrap;
    line-height: 1.6;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    width: max-content;
    display: block;
    svg {
      margin: 0px 9px 0px 0px;
      fill: #a8cde5;
      vertical-align: middle;
    }
    .PPTM-btm {
      fill: #a8cde5;
    }
    .PPTM-top {
      fill: #ffffff;
    }
    b {
      color: #fff;
    }
  }
  .connect-to-paypal:hover,
  .connect-to-paypal:active {
    background-image: -webkit-linear-gradient(#003087 20%, #003087) !important;
    background-image: linear-gradient(#003087 20%, #003087) !important;
    background-repeat: no-repeat !important;
  }
  .connect-to-paypal:hover b {
    text-decoration: underline;
  }
}

.welcome-title, .welcome-question {
    .Polaris-Text--root {
    text-align: center;
    line-height: 32px;
    font-size: 26px;
    font-weight: 400;
    }
    margin-bottom: 15px;
}
.welcome-description {
    .Polaris-Text--bodyMd {
        font-size: 16px;
        color: rgb(109 113 117);
        text-align: center;
    }
}
.mt-20 {
    margin-top: 20px;
}
.button-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.image-center{
    display: flex;
    justify-content: center;
    margin: 16px auto;
}
`
