import styled from "styled-components";

export const CustomSettingToggleStyled = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Polaris-Badge {
        margin-left: 4px;
    }
`