const search = window.location.search.substring(1);
const convertPathname = (pathname: string) => {
  return {
    pathname: `${pathname ? '/' + pathname : ''}`,
    search: '?' + search,
  };
};

export const PATH = {
  DEFAULT: convertPathname(''),
  BLOCKLIST: convertPathname('home'),
  DASHBOARD: convertPathname('dashboard'),
  ANALYTICS: convertPathname('analytics'),
  SETTINGS: convertPathname('settings'),
  CUSTOMTEMPLATE: convertPathname('custom-template'),
  WELCOME: convertPathname('welcome'),
};
