import { memo, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { chartBlockSelector } from '@/redux/slice/dashboard.slice';
import { apiCaller } from '@/redux/query';
import { scaleLinear } from 'd3-scale';
import { colors, Enum } from '@/constants';
const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';

const HeatMap = () => {
  const [zoom, setZoom] = useState(false);
  const getCountryISO2 = require('country-iso-3-to-2');
  const ChartData = useSelector(chartBlockSelector);
  const { data } = apiCaller.useFetchChartBlockQuery({ ...ChartData });
  const [state, setState] = useState({ content: '', maxAccess: 0 });
  const isMediumScreen = useMediaQuery({ maxWidth: Enum.MediaScreen.MEDIUM });
  useEffect(() => {
    if (data?.data?.heatMaps?.length) {
      const { heatMaps } = data?.data;
      const arrAccess = heatMaps?.map((item) => item.total);
      const max = Math.max(...arrAccess);
      setState((prevState) => ({ ...prevState, maxAccess: max }));
    }
  }, [data]);

  const arrColor = [colors.gray_background, colors.critical] as Iterable<number>;
  const colorScale = scaleLinear().domain([0, state.maxAccess]).range(arrColor);
  return (
    <div onClick={() => setZoom(true)}>
      <ComposableMap
        projectionConfig={{
          scale: isMediumScreen ? 150 : 200,
          center: [10, 0],
        }}
        style={{
          width: '100%',
          maxHeight: '550',
          pointerEvents: zoom ? 'auto' : 'none',
        }}
      >
        <ZoomableGroup center={[0, 0]} onMouseLeave={() => setZoom(false)}>
          <Geographies geography={geoUrl} aria-disabled>
            {({ geographies }: any) =>
              geographies.map((geo: any) => {
                const country = data?.data?.heatMaps?.find((item) => item.countryCode === getCountryISO2(geo.id));
                return (
                  <Geography
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={state.content}
                    onMouseEnter={() => {
                      setState({
                        ...state,
                        content: `${geo.properties.name} — ${country ? country.total : 0}`,
                      });
                    }}
                    onMouseLeave={() => {
                      setState({ ...state, content: '' });
                    }}
                    fill={country ? colorScale(country.total).toString() : colors.gray_background}
                    style={{
                      default: {
                        fill: country ? colorScale(country.total).toString() : colors.gray_background,
                        outline: 'none',
                      },
                      hover: {
                        fill: colors.critical,
                        outline: 'none',
                      },
                      pressed: {
                        fill: colors.critical,
                        outline: 'none',
                      },
                    }}
                    geography={geo}
                    key={geo.rsmKey}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip float id="my-tooltip" />
    </div>
  );
};
export default memo(HeatMap);
