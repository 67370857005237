import blockify from '@/asset/images/blockify.png';
import logo from '@/asset/images/logo.jpg';
import lockIcon from '@/asset/images/lockIcon.png';
import visitorScreen from '@/asset/images/visitorAnalyticsScreen.png';
import visitorScreenShot from '@/asset/images/visitorScreen.png';
import illustration from '@/asset/images/Illustration.png';
import insurance from '@/asset/images/Insurance.png';
import publishSite from '@/asset/images/publishSite.png';
import review from '@/asset/images/review.png';
import review2 from '@/asset/images/review2.png';
import block_collection from '@/asset/images/block_collection.png';
import block_isp from '@/asset/images/block_isp.png';
import block_state from '@/asset/images/block_state.png';
import block_ip_range from '@/asset/images/block_ip_range.png';
import block_ip_address from '@/asset/images/block_ip_address.png';
import block_country from '@/asset/images/block_country.png';
export const images = {
  blockify,
  logo,
  lockIcon,
  visitorScreen,
  visitorScreenShot,
  illustration,
  insurance,
  publishSite,
  review,
  review2,
  block_collection,
  block_isp,
  block_state,
  block_ip_range,
  block_ip_address,
  block_country,
};
