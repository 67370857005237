import React, { useCallback, useState } from 'react';
import { getLast6Months, getLastMonth, getSomeDaysAgo, getLastYear } from '@/helpers';
import { Box, Button, DatePicker, Icon, OptionList, Popover, TextField, useBreakpoints } from '@shopify/polaris';
import { ArrowRightMinor, CalendarMinor } from '@shopify/polaris-icons';
import moment from 'moment';

interface Props {
  titleButton: string;
  setTitleButton: Function;
  startDate?: Date;
  endDate?: Date;
  onSave: Function;
}
const ranges = [
  {
    title: 'Custom',
    alias: 'Custom',
    period: {
      end: new Date(),
      start: new Date(),
    },
  },
  {
    title: 'Today',
    alias: 'today',
    period: {
      end: new Date(),
      start: new Date(),
    },
  },
  {
    title: 'Yesterday',
    alias: 'yesterday',
    period: {
      end: getSomeDaysAgo(1),
      start: getSomeDaysAgo(1),
    },
  },
  {
    title: 'Last 7 days',
    alias: 'last7days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(7),
    },
  },
  {
    title: 'Last 30 days',
    alias: 'last30days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(30),
    },
  },
  {
    title: 'Last month',
    alias: 'lastmonth',
    period: {
      end: getLastMonth().end,
      start: getLastMonth().start,
    },
  },
  {
    title: 'Last 90 days',
    alias: 'last90days',
    period: {
      end: new Date(),
      start: getSomeDaysAgo(90),
    },
  },
  {
    title: 'Last 6 months',
    alias: 'last6months',
    period: {
      end: new Date(),
      start: getLast6Months(),
    },
  },
  {
    title: 'Last Year',
    alias: 'lastyear',
    period: {
      end: new Date(),
      start: getLastYear(),
    },
  },
];
function CustomDatePicker(props: Props) {
  const now = new Date();
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState(ranges[3]);
  const [selectedDates, setSelectedDates] = useState({ start: getSomeDaysAgo(7), end: new Date() });
  const { mdDown } = useBreakpoints();
  const [{ month, year }, setDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);
  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button icon={CalendarMinor} onClick={() => setPopoverActive(!popoverActive)}>
          {props.titleButton}
        </Button>
      }
      onClose={() => {
        setSelectedDates({ start: props.startDate || new Date(), end: props.startDate || new Date() });
        setPopoverActive(false);
      }}
    >
      <div className="d-flex">
        <OptionList
          options={ranges.map((range) => ({
            value: range.alias,
            label: range.title,
          }))}
          selected={[selected.alias]}
          onChange={(value) => {
            const range = ranges.find((range) => range.alias === value[0]) || ranges[0];
            setSelected(range);
            setSelectedDates({ ...range.period });
            handleMonthChange(range.period.start.getMonth(), range.period.start.getFullYear());
          }}
        />
        <Box padding={{ xs: '5' }} maxWidth={mdDown ? '320px' : '516px'}>
          <div className="d-flex">
            <div>
              <TextField
                role="combobox"
                label={'Since'}
                labelHidden
                prefix={<Icon source={CalendarMinor} />}
                value={moment(selectedDates.start).format('DD/MM/YYYY')}
                readOnly
                autoComplete="off"
              />
            </div>
            <Icon source={ArrowRightMinor} />
            <div>
              <TextField
                role="combobox"
                label={'Until'}
                labelHidden
                prefix={<Icon source={CalendarMinor} />}
                value={moment(selectedDates.end).format('DD/MM/YYYY')}
                autoComplete="off"
                readOnly
              />
            </div>
          </div>
          <DatePicker
            month={month}
            year={year}
            selected={{
              start: selectedDates.start,
              end: selectedDates.end,
            }}
            onMonthChange={handleMonthChange}
            onChange={(value) => {
              setSelectedDates(value);
              setSelected(ranges[0]);
            }}
            multiMonth
            allowRange
            disableDatesAfter={now}
            disableDatesBefore={selected.title === 'Last Year' ? selected.period.start : undefined}
          />
          <div className="date-picker-btn-group">
            <Button
              onClick={() => {
                setSelectedDates({ start: props.startDate || new Date(), end: props.endDate || new Date() });
                setPopoverActive(false);
              }}
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button
                primary
                onClick={() => {
                  setPopoverActive(false);
                  props.onSave(selectedDates.start, selectedDates.end);
                  props.setTitleButton(
                    selected.title === 'Custom'
                      ? `${moment(selectedDates.start).format('DD/MM/YYYY')} - ${moment(selectedDates.end).format('DD/MM/YYYY')}`
                      : selected.title,
                  );
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </Popover>
  );
}

export default CustomDatePicker;
