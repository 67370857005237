import { images } from '@/asset';
import { config } from '@/config';
import { Enum, PATH } from '@/constants';
import { apiCaller } from '@/redux/query';
import settingsSlice, { templateSavedSelector } from '@/redux/slice/settings.slice';
import { AlphaCard, Button, ButtonGroup, hsbToHex, Tooltip } from '@shopify/polaris';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TemplateStyled } from '../../styled';
import { ISettings } from '@/types/components';
import { hexToHSB } from '@/helpers';

const btnGroup = [
  {
    icon: DesktopMajor,
    fontSize: '16px',
  },
  {
    icon: MobileMajor,
    fontSize: '10px',
  },
];

const CustomTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const template = useSelector(templateSavedSelector);
  const generalDetail = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const handleButtonClick = useCallback(
    (index: number) => {
      if (activeButtonIndex === index) return;
      setActiveButtonIndex(index);
    },
    [activeButtonIndex],
  );

  useEffect(() => {
    if (generalDetail.data && generalDetail.data.settings.blockTemplate) {
      const settings = generalDetail.data.settings.blockTemplate;
      const template: ISettings.CustomTemplate = {
        backgroundColor:
          settings.backgroundType === Enum.BackgroundType.Color ? hexToHSB(settings.background) : hexToHSB('#ffffff'),
        backgroundImage: settings.backgroundType === Enum.BackgroundType.Image ? settings.background : '',
        backgroundType: settings.backgroundType,
        content: settings.description,
        contentColor: hexToHSB(settings.descriptionColor),
        image: settings.logoImage,
        title: settings.title,
        titleColor: hexToHSB(settings.titleColor),
      };
      dispatch(settingsSlice.actions.handleTemplate({ ...template }));
      dispatch(settingsSlice.actions.handleTemplateSaved({ ...template }));
      if (settings.backgroundType === Enum.BackgroundType.Color) {
        dispatch(settingsSlice.actions.handleBackgroundColor(hexToHSB(settings.backgroundType)));
      }
      dispatch(settingsSlice.actions.handleContentColor(hexToHSB(settings.descriptionColor)));
      dispatch(settingsSlice.actions.handleTitleColor(hexToHSB(settings.titleColor)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalDetail?.data?.settings.blockTemplate]);
  return (
    <AlphaCard>
      <div className="d-flex flex-end mt-16">
        <ButtonGroup segmented>
          {btnGroup.map((item, index) => {
            return (
              <Button
                key={index}
                icon={item.icon}
                primary={activeButtonIndex === index}
                onClick={() => handleButtonClick(index)}
              />
            );
          })}
        </ButtonGroup>
      </div>
      <TemplateStyled
        template={{
          backgroundColor: hsbToHex(template.backgroundColor),
          contentColor: hsbToHex(template.contentColor),
          titleColor: hsbToHex(template.titleColor),
          backgroundImage: template.backgroundImage,
          backgroundType: template.backgroundType,
        }}
        fontSize={btnGroup[activeButtonIndex].fontSize}
      >
        {generalDetail.data ?
          <div className="template-container">
            <img className="block-img" src={generalDetail.data.settings.user.plan === Enum.UserPlan.FREE || !template.image ? images.blockify : template.image} alt="logo" />
            <div className="mt-3">
              <div className="fz-1-25">{template.title}</div>
            </div>
            <div className="mt-1-25 template-content">
              <div className="fz-0-875 content">{template.content}</div>
            </div>
            {generalDetail.data?.settings.user.plan === Enum.UserPlan.FREE ? (
              <div className="d-flex mt-3">
                <div className="fz-0-875">Protected by</div>
                <img className="template-logo" src={images.logo} alt="blockify" />
                <div className="fz-0-875">Blockify™</div>
              </div>
            ) : null}
          </div>
          : null
        }
      </TemplateStyled>
      <div className="d-flex flex-end">
        {generalDetail.data?.settings.user.plan === Enum.UserPlan.FREE ?
          <Tooltip
            content={
              <p>
                Available on {Enum.UserPlan.BASIC} plan or above!
              </p>
            }
          >
            <Button
              disabled={generalDetail.data?.settings.user.plan === Enum.UserPlan.FREE}
              primary
              onClick={() => navigate(PATH.CUSTOMTEMPLATE)}
            >
              Launch template editor
            </Button>
          </Tooltip>
          :
          <Button
            primary
            onClick={() => navigate(PATH.CUSTOMTEMPLATE)}
          >
            Launch template editor
          </Button>
        }
      </div>
    </AlphaCard>
  );
};
export default memo(CustomTemplate);
