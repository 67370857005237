import { colors } from '@/constants';
import styled from 'styled-components';

export const BlockListStyled = styled.div`
  .Polaris-ResourceList__ResourceListWrapper {
    overflow-x: auto;
    .Polaris-ResourceList {
      min-width: 900px;
    }
    .remove-btn {
      &:hover {
        border-color: ${colors.critical};
        svg {
          fill: ${colors.critical};
        }
      }
    }
    .edit-btn {
      &:hover {
        border-color: ${colors.primary};
        svg {
          fill: ${colors.primary};
        }
      }
    }
  }
  .table-content {
    justify-content: space-between;
  }
  .header {
    .Polaris-Icon {
      margin: 0% 8px;
    }
  }
  .d-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  nav {
    display: flex;
    justify-content: center;
  }
  .add-rule-container {
    background-color: white;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.gray_background};
    }
  }
  .add-rule-img {
    margin-bottom: 8px;
    max-width: 100%;
    height: 50px;
  }
  .add-rule-title {
    align-items: center;
    .Polaris-Icon {
      margin: 0;
    }
  }
  .Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .div-disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;
