import { useCallback, useState } from 'react';
import Card from '@/components/card';
import { apiCaller } from '@/redux/query';
import visitorLogSlice, { inputSearchSelector, visitorLogSelector } from '@/redux/slice/visitorAnalytics.slice';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import {
  Badge,
  Button,
  ChoiceList,
  EmptyState,
  Filters,
  Icon,
  Pagination,
  ResourceItem,
  ResourceList,
  Text,
  Tooltip,
} from '@shopify/polaris';
import {
  DesktopMajor,
  ImportMinor,
  MobileMajor,
  SortAscendingMajor,
  SortDescendingMajor,
  SortMinor,
} from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import options from '../../options';
import { Enum } from '@/constants';
import toastSlice from '../../../../redux/slice/toast.slice';
import { perPageOptions } from '@/constants/options';
import { config } from '@/config';

const headerTable: IResponseApi.VisitorItem = {
  id: '0',
  ip: '',
  countryCode: '',
  countryName: '',
  cityName: '',
  totalAccess: '',
  lastVisitedTime: '',
  type: '',
  risk: 0,
  status: '',
  device: Enum.DeviceType.DESKTOP,
};
const configWidthColTable = {
  ipBlocked: '160px',
  country: '80px',
  city: '120px',
  totalAccess: '150px',
  lastVisitedTime: '200px',
  type: '100px',
  device: '100px',
  risk: '100px',
  status: '100px',
  action: '120px',
};

const handleIconSort = (direction: string) => {
  if (direction === 'ASC') {
    return SortAscendingMajor;
  }
  return SortDescendingMajor;
};

const TableVisitorLog = (): JSX.Element => {
  const dispatch = useDispatch();
  const visitorLogTable = useSelector(visitorLogSelector);
  const inputSearch = useSelector(inputSearchSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearch = useCallback(
    _debounce((value: IParamsApi.IGetVisitorLog) => {
      dispatch(visitorLogSlice.actions.handleVisitorLogTable(value));
    }, 1000),
    [],
  );
  const emptyStateMarkup = (
    <EmptyState heading="No Item" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
  );
  const [addBlackList, addBlackListStatus] = apiCaller.useAddBlackListMutation();
  const [id, setId] = useState('');
  const { data, isLoading } = apiCaller.useFetchVisitorListQuery({
    ...visitorLogTable,
    risk: visitorLogTable.risk ? visitorLogTable.risk : undefined,
    type: visitorLogTable.type ? visitorLogTable.type : undefined,
  });
  const dataSettings = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  const updateSort = (pattern: any) => {
    dispatch(
      visitorLogSlice.actions.handleVisitorLogTable({
        ...visitorLogTable,
        sortBy: pattern,
        sort: visitorLogTable.sort === Enum.SortType.DESC ? Enum.SortType.ASC : Enum.SortType.DESC,
        page: 1,
      }),
    );
  };

  const filters = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[visitorLogTable.perPage || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                perPage: value[0],
                page: 1,
              }),
            );
          }}
        />
      ),
      shortcut: true,
    },
    {
      key: 'status',
      label: 'Status',
      shortcut: true,
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={options.statusOptions}
          selected={[visitorLogTable.status || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                status: value[0],
              }),
            );
          }}
        />
      ),
    },
    {
      key: 'risk',
      label: 'Risk',
      shortcut: true,
      filter: (
        <ChoiceList
          title="Risk"
          titleHidden
          choices={options.riskOptions}
          selected={[visitorLogTable.risk || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                risk: value[0],
              }),
            );
          }}
        />
      ),
    },
    {
      key: 'type',
      label: 'Type',
      shortcut: true,
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={options.typeOptions}
          selected={[visitorLogTable.type || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                type: value[0],
              }),
            );
          }}
        />
      ),
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + visitorLogTable.perPage,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            type: '',
            page: 1,
          }),
        ),
      key: 'type',
      label: `Type: ${options.typeOptions.find((item) => item.value === visitorLogTable.type)?.label || 'All'}`,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            status: '',
            page: 1,
          }),
        ),
      key: 'status',
      label: `Status: ${options.statusOptions.find((item) => item.value === visitorLogTable.status)?.label || 'All'}`,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            risk: '',
            page: 1,
          }),
        ),
      key: 'risk',
      label: `Risk: ${options.riskOptions.find((item) => item.value === visitorLogTable.risk)?.label || 'All'}`,
    },
  ];

  const filterControl = (
    <Filters
      appliedFilters={appliedFilters}
      queryValue={inputSearch}
      filters={filters}
      onQueryChange={(value) => {
        const phoneRegEx = new RegExp('^[a-zA-Z0-9_.#.-]*$');
        if (!phoneRegEx.test(value)) {
          dispatch(
            toastSlice.actions.handleToast({
              isOpen: true,
              content: 'You entered the wrong format',
              error: true,
            }),
          );
        } else {
          dispatch(visitorLogSlice.actions.handleInputSearch(value));
          debounceSetSearch({
            ...visitorLogTable,
            search: value,
            page: 1,
          });
        }
      }}
      onQueryClear={() => {
        dispatch(visitorLogSlice.actions.handleInputSearch(''));
        debounceSetSearch({
          ...visitorLogTable,
          search: '',
          page: 1,
        });
      }}
      queryPlaceholder="Search by IP address or Location"
      onClearAll={() => { }}
    />
  );

  const RenderItem = (visitorLogItem: IResponseApi.VisitorItem): JSX.Element => {
    const riskLevel = visitorLogItem.risk;
    let badgeRisk;
    if (!riskLevel && riskLevel !== 0) badgeRisk = <Badge>N/A</Badge>;
    else {
      if (riskLevel <= Enum.RiskLevel.Safe) {
        badgeRisk = <Badge status="success">Safe</Badge>;
      } else if (Enum.RiskLevel.Safe < riskLevel && riskLevel <= Enum.RiskLevel.Risky) {
        badgeRisk = <Badge status="warning">Risky</Badge>;
      } else {
        badgeRisk = <Badge status="critical">Dangerous</Badge>;
      }
    }
    const timezone = dataSettings?.data?.settings?.timezone ? JSON.parse(dataSettings?.data?.settings?.timezone) : ''
    const time = new Date(visitorLogItem.lastVisitedTime);
    const timeWithTimezone = time;
    // Convert time to match lastVisitTime of database (-7hours)
    const timeWithTimezoneDisplay = new Date(timeWithTimezone.setUTCHours((timeWithTimezone.getUTCHours() - 7) + timezone.offset))
    timeWithTimezoneDisplay.setUTCMinutes(time.getUTCMinutes() + timezone.offset % 1 * 60);
    return (
      <ResourceItem onClick={() => { }} id={visitorLogItem.id}>
        {visitorLogItem.id === '0' ? (
          <div className="table-content header d-flex">
            <div style={{ width: configWidthColTable.ipBlocked, alignItems: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                IP Address
              </Text>
            </div>

            <div style={{ width: configWidthColTable.country, alignItems: 'center', justifyContent: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Country
              </Text>
            </div>

            <div style={{ width: configWidthColTable.city, alignItems: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                City
              </Text>
            </div>

            <div
              style={{ width: configWidthColTable.totalAccess, alignItems: 'center' }}
              className="d-flex"
              onClick={() => updateSort('totalAccess')}
            >
              <Text as="h6" variant="headingMd">
                Total Access
                <Text variant="headingXs" as="h6">
                  (in chosen period)
                </Text>
              </Text>
              <Icon
                source={
                  // eslint-disable-next-line no-nested-ternary
                  visitorLogTable.sortBy === 'totalAccess' ? handleIconSort(visitorLogTable.sort) : SortMinor
                }
                color={visitorLogTable.sortBy === 'totalAccess' ? 'primary' : 'base'}
              />
            </div>

            <div
              style={{ width: configWidthColTable.lastVisitedTime, alignItems: 'center' }}
              className="d-flex"
              onClick={() => updateSort('lastVisitedTime')}
            >
              <Text as="h6" variant="headingMd">
                Last Seen On
                <Text variant="headingXs" as="h6">
                  {timezone?.label?.split(' ')[0]}
                </Text>
              </Text>
              <Icon
                source={
                  // eslint-disable-next-line no-nested-ternary
                  visitorLogTable.sortBy === 'lastVisitedTime' ? handleIconSort(visitorLogTable.sort) : SortMinor
                }
                color={visitorLogTable.sortBy === 'lastVisitedTime' ? 'primary' : 'base'}
              />
            </div>

            <div style={{ width: configWidthColTable.type, alignItems: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Type
              </Text>
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.device, alignItems: 'center', justifyContent: 'center' }}>
              <Text as="h6" variant="headingMd">
                Device
              </Text>
            </div>
            <div style={{ width: configWidthColTable.risk, alignItems: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Risk
              </Text>
            </div>
            <div style={{ width: configWidthColTable.status, alignItems: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Status
              </Text>
            </div>
            <div style={{ width: configWidthColTable.action, alignItems: 'center' }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Action
              </Text>
            </div>
          </div>
        ) : (
          <div className="table-content d-flex">
            <div className="d-flex" style={{ width: configWidthColTable.ipBlocked, alignItems: 'center' }}>
              {visitorLogItem.ip.length > 20 ? (
                <Tooltip dismissOnMouseOut content={visitorLogItem.ip}>
                  <Text as="h6" variant="bodyMd">
                    {`${visitorLogItem.ip.slice(0, 20)}...`}
                  </Text>
                </Tooltip>
              ) : (
                <Text as="h6" variant="bodyMd">
                  {visitorLogItem.ip}
                </Text>
              )}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.country, alignItems: 'center', justifyContent: 'center' }}>
              {visitorLogItem.countryName
                ?
                <Tooltip dismissOnMouseOut content={visitorLogItem.countryName}>
                  <ReactCountryFlag svg className="emojiFlag" countryCode={visitorLogItem.countryCode} />
                </Tooltip>
                :
                <ReactCountryFlag svg className="emojiFlag" countryCode={visitorLogItem.countryCode} />
              }
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.city, alignItems: 'center' }}>
              {visitorLogItem.cityName?.length > 15
                ?
                <Tooltip dismissOnMouseOut content={visitorLogItem.cityName}>
                  <Text as="h6" variant="bodyMd">
                    {visitorLogItem.cityName}
                  </Text>
                </Tooltip>
                :
                <Text as="h6" variant="bodyMd">
                  {visitorLogItem.cityName}
                </Text>
              }
            </div>
            <div
              className="d-flex"
              style={{ width: configWidthColTable.totalAccess, alignItems: 'center', justifyContent: 'center' }}
            >
              {visitorLogItem.totalAccess}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.lastVisitedTime, alignItems: 'center' }}>
              {moment(timezone?.offset ? timeWithTimezoneDisplay : visitorLogItem.lastVisitedTime).format('MMM Do YYYY, h:mm:ss a')}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.type, alignItems: 'center' }}>
              {visitorLogItem.type}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.device, alignItems: 'center' }}>
              {visitorLogItem.device === Enum.DeviceType.DESKTOP ? <Icon source={DesktopMajor} /> : <Icon source={MobileMajor} />}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.risk, alignItems: 'center' }}>
              {badgeRisk}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.status, alignItems: 'center' }}>
              {visitorLogItem.status === 'allow' ? (
                <Badge status="success">{visitorLogItem.status.charAt(0).toUpperCase() + visitorLogItem.status.slice(1)}</Badge>
              ) : (
                <Badge status="critical">{visitorLogItem.status.charAt(0).toUpperCase() + visitorLogItem.status.slice(1)}</Badge>
              )}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.action, alignItems: 'center' }}>
              <Button
                disabled={visitorLogItem.status === 'block'}
                destructive
                loading={addBlackListStatus.isLoading && visitorLogItem.id === id}
                onClick={() => {
                  addBlackList({
                    type: Enum.ActionType.Block,
                    criteria: Enum.CriteriaType.IpAddress,
                    ipAddress: visitorLogItem.ip,
                    priority: Enum.ListType.BlackList,
                  });
                  setId(visitorLogItem.id);
                }}
              >
                Block
              </Button>
            </div>
          </div>
        )
        }
      </ResourceItem >
    );
  };

  const handleExportHistory = () => {
    window.open(`${process.env.REACT_APP_API_END_POINT}visitor/export?instance=${config.instance}`);
  };

  return (
    <div>
      <Card
        title="Visitors log"
        actions={{ content: 'Export', buttonProps: { size: 'medium', icon: ImportMinor }, onAction: handleExportHistory }}
      >
        <ResourceList
          resourceName={resourceName}
          items={data && data?.listIp?.length > 0 ? [headerTable, ...data.listIp] : []}
          renderItem={RenderItem}
          loading={isLoading}
          filterControl={filterControl}
          emptyState={emptyStateMarkup}
        />
        <div className="mt-16 d-flex" style={{ justifyContent: 'center' }}>
          <Pagination
            label={
              data?.listIp?.length
                ? `Showing ${(visitorLogTable.page - 1) * Number(visitorLogTable.perPage) + 1} to ${Math.min(
                  visitorLogTable.page * Number(visitorLogTable.perPage),
                  data?.meta.totalItems,
                )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1}
            onPrevious={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogTable({
                  ...visitorLogTable,
                  page: visitorLogTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(visitorLogTable.perPage))}
            onNext={() => {
              dispatch(
                visitorLogSlice.actions.handleVisitorLogTable({
                  ...visitorLogTable,
                  page: visitorLogTable.page + 1,
                }),
              );
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default TableVisitorLog;
