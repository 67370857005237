import { Enum } from '@/constants';
import styled from 'styled-components';

interface TemplateStyledProps {
  fontSize: string;
  template: {
    backgroundImage?: string;
    titleColor: string;
    contentColor: string;
    backgroundColor: string;
    backgroundType: string;
  };
}

export const SettingsStyled = styled.div`
  .mr-8 {
    margin-right: 8px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
`;

export const TemplateStyled = styled.div<TemplateStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  .template-container {
    font-size: ${(props) => props.fontSize};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: ${(props) => (props.fontSize === '10px' ? '2em 30%' : '2em 3em')};
    padding: 2em;
    border: 1px solid #000000;
    margin-top: 1em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${(props) => (props.template.backgroundType === '2' ? props.template.backgroundImage : 'none')});
    background-color: ${(props) =>
      props.template.backgroundType === Enum.BackgroundType.Color ? props.template.backgroundColor : 'white'};

    .fz-0-875 {
      font-size: 0.875em;
      color: #6d7175;
      text-align: center;
    }
    .content {
      color: ${(props) => props.template.contentColor} !important;
    }
    .fz-1-25 {
      font-size: 1.25em;
      color: ${(props) => props.template.titleColor};
      text-align: center;
    }
    .block-img {
      width: 8.125em;
      height: 8.125em;
    }
    .template-content {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .template-logo {
      margin: 0 0.25em;
    }
    .mt-3 {
      margin-top: 3em;
    }
    .mt-1-25 {
      margin-top: 1.25em;
    }
  }
`;

export const TemplateFullScreenStyled = styled.div<TemplateStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  .full-template-container {
    font-size: ${(props) => props.fontSize};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    padding: 4em;
    margin: ${(props) => (props.fontSize === '10px' ? '2em 30%' : '1em 0')};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${(props) => (props.template.backgroundType === '2' ? props.template.backgroundImage : 'none')});
    background-color: ${(props) =>
      props.template.backgroundType === Enum.BackgroundType.Color ? props.template.backgroundColor : 'white'};
    .fz-3-75 {
      color: ${(props) => props.template.titleColor};
      font-size: 3.75em;
      line-height: 1.25em;
      text-align: center;
    }
    .fz-1-5 {
      font-size: 1.5em;
      line-height: 2.5em;
      line-height: 1.5em;
      text-align: center;
      color: #6d7175;
    }
    .content {
      color: ${(props) => props.template.contentColor};
    }
    .block-img {
      width: 16.625em;
      height: 16.625em;
    }
    .template-content {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .template-logo {
      width: 2.5em;
      height: 2.5em;
      margin: 0 0.25em;
    }
    .footer {
      padding: 0.75em 1.5em;
      background-color: white;
      border-radius: 0.75em;
    }
    .break-line {
      word-break: break-all;
    }
    .mt-4 {
      margin-top: 4em;
    }
    .mt-6 {
      margin-top: 6em;
    }
    .mt-2-5 {
      margin-top: 2.5em;
    }
  }
`;

export const FullScreenStyled = styled.div`
  margin-top: 16px;
  .Polaris-Layout {
    min-width: calc(100vw - 72px);
  }
`;

export const CardContentProtectionStyled = styled.div`
  .Polaris-LegacyCard {
    border-radius: none;
    box-shadow: none;
  }
`;