import { memo, useState } from 'react';
import { apiCaller } from '@/redux/query';
import { config } from '@/config';
import { useDispatch } from 'react-redux';
import toastSlice from '@/redux/slice/toast.slice';
import { Enum } from '@/constants';
import { handleToastMuatation } from '@/helpers';
import { LegacyCard } from '@shopify/polaris';
import { IParamsApi } from '@/types/api/params.api';
import SettingProtection from '../settingProtection';

const BlockerAndRedirector = () => {
    const { data, isFetching } = apiCaller.useGetGeneralDetailQuery(config.instanceId);
    const [activeVpn, activeVpnStatus] = apiCaller.useActiveProxyVpnMutation();
    const [activeAutoBlock, autoBlockStatus] = apiCaller.useActiveAutoBlockMutation();
    const [activeTorBlock, torBlockStatus] = apiCaller.useActiveTorBlockMutation();
    const [buttonClick, setButtonClick] = useState('');
    const dispatch = useDispatch();
    const handleActiveVpn = () => {
        activeVpn(!data?.settings.activeVpnProxy).then((res) => {
            if ('data' in res && res.data.state === 0) {
                dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
            } else {
                dispatch(toastSlice.actions.handleToast({
                    isOpen: true,
                    content: 'Updated',
                    error: false,
                }));
            }
        });
        setButtonClick('1')
    };
    const handleActiveAutoBlock = () => {
        const autoBlock: IParamsApi.IContentProtection = {
            autoBlock: !data?.settings.autoBlock,
        };
        activeAutoBlock(autoBlock).then((res) => {
            if ('data' in res && res.data.state === 0) {
                dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
            } else {
                dispatch(toastSlice.actions.handleToast({
                    isOpen: true,
                    content: 'Updated',
                    error: false,
                }));
            }
        });
        setButtonClick('2')
    };
    const handleActiveTorBlock = () => {
        const torBlock: IParamsApi.IContentProtection = {
            torBlock: !data?.settings.torBlock,
        };
        activeTorBlock(torBlock).then((res) => {
            if ('data' in res && res.data.state === 0) {
                dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
            } else {
                dispatch(toastSlice.actions.handleToast({
                    isOpen: true,
                    content: 'Updated',
                    error: false,
                }));
            }
        });
        setButtonClick('3')
    };

    return (
        <div>
            {data ? (
                <div>
                    <LegacyCard>
                        <SettingProtection
                            title="Proxy and VPN blocker"
                            content="Turn on to automatically block visitors who use Proxy or VPN."
                            enabled={data.settings.activeVpnProxy}
                            plan={Enum.UserPlan.BASIC}
                            loading={activeVpnStatus.isLoading || (isFetching && buttonClick === '1')}
                            handleProtection={handleActiveVpn}
                            disable={data.settings.user.plan === Enum.UserPlan.FREE}
                        />
                    </LegacyCard>
                    <LegacyCard>
                        <SettingProtection
                            title="Block visitors by their devices"
                            content="Turn on to auto block visitors even when they change their IP addresses (block any connection to your website that comes from visitors’ devices)."
                            enabled={data.settings.autoBlock}
                            plan={Enum.UserPlan.BASIC}
                            loading={autoBlockStatus.isLoading || (isFetching && buttonClick === '2')}
                            handleProtection={handleActiveAutoBlock}
                            disable={data.settings.user.plan === Enum.UserPlan.FREE}
                        />
                    </LegacyCard>
                    <LegacyCard>
                        <SettingProtection
                            title="Tor blocker"
                            content="Turn on to automatically block visitors who use Tor."
                            enabled={data.settings.torBlock}
                            plan={Enum.UserPlan.ADVANCE}
                            loading={torBlockStatus.isLoading || (isFetching && buttonClick === '3')}
                            handleProtection={handleActiveTorBlock}
                            disable={data.settings.user.plan === Enum.UserPlan.FREE || data.settings.user.plan === Enum.UserPlan.BASIC}
                        />
                    </LegacyCard>
                </div>

            ) : null}
        </div>
    );
};
export default memo(BlockerAndRedirector);
