import Card from '@/components/card';
import { config } from '@/config';
import { Enum } from '@/constants';
import { perPageOptions } from '@/constants/options';
import { apiCaller } from '@/redux/query';
import dashboardSlice, { blockHistorySelector, inputSearchSelector } from '@/redux/slice/dashboard.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import { ChoiceList, EmptyState, Filters, Icon, Pagination, ResourceItem, ResourceList, Text, Tooltip } from '@shopify/polaris';
import { ImportMinor, SortAscendingMajor, SortDescendingMajor, SortMinor } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { useCallback } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';

const headerTable: IResponseApi.IBlockedHistory = {
  id: 0,
  ip: '',
  totalAccess: '',
  lastVisitedTime: '',
  countryCode: '',
  countryName: '',
  cityName: '',
};
const configWidthColTable = {
  ipBlocked: '180px',
  country: '180px',
  province: '150px',
  totalAccess: '170px',
  lastestTime: '200px',
};

const handleIconSort = (direction: string) => {
  if (direction === 'ASC') {
    return SortAscendingMajor;
  }
  return SortDescendingMajor;
};

const TableBlockedHistory = (): JSX.Element => {
  const dispatch = useDispatch();
  const blockHistoryTable = useSelector(blockHistorySelector);
  const inputSearch = useSelector(inputSearchSelector);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearch = useCallback(
    _debounce((value: IParamsApi.IGetAddressBlock) => {
      dispatch(dashboardSlice.actions.handleBlockHistoryTable(value));
    }, 1000),
    [],
  );
  const emptyStateMarkup = (
    <EmptyState heading="No Item" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
  );
  const dataSettings = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const { data, isLoading } = apiCaller.useFetchVisitorBlockListQuery({
    ...blockHistoryTable,
    risk: blockHistoryTable.risk ? blockHistoryTable.risk : undefined,
    type: blockHistoryTable.type ? blockHistoryTable.type : undefined,
  });
  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  const updateSort = (pattern: any) => {
    dispatch(
      dashboardSlice.actions.handleBlockHistoryTable({
        ...blockHistoryTable,
        sortBy: pattern,
        sort: blockHistoryTable.sort === Enum.SortType.DESC ? Enum.SortType.ASC : Enum.SortType.DESC,
        page: 1,
      }),
    );
  };

  const filters = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[blockHistoryTable.perPage]}
          onChange={(value) => {
            dispatch(
              dashboardSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                perPage: value[0],
                page: 1,
              }),
            );
          }}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          dashboardSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + blockHistoryTable.perPage,
    },
  ];

  const filterControl = (
    <Filters
      appliedFilters={appliedFilters}
      queryValue={inputSearch}
      filters={filters}
      onQueryChange={(value) => {
        const phoneRegEx = new RegExp('^[a-zA-Z0-9_.#.-]*$');
        if (!phoneRegEx.test(value)) {
          dispatch(
            toastSlice.actions.handleToast({
              isOpen: true,
              content: 'Search content is not in the correct format',
              error: false,
            }),
          );
        } else {
          dispatch(dashboardSlice.actions.handleInputSearch(value));
          debounceSetSearch({
            ...blockHistoryTable,
            search: value,
            page: 1,
          });
        }
      }}
      onQueryClear={() => {
        dispatch(dashboardSlice.actions.handleInputSearch(''));
        debounceSetSearch({
          ...blockHistoryTable,
          search: '',
          page: 1,
        });
      }}
      queryPlaceholder="Search by IP address/country/province/city ..."
      onClearAll={() => { }}
    ></Filters>
  );

  const RenderItem = (blockItem: IResponseApi.IBlockedHistory): JSX.Element => {
    const timezone = dataSettings?.data?.settings?.timezone ? JSON.parse(dataSettings?.data?.settings?.timezone) : ''
    const time = new Date(blockItem.lastVisitedTime);
    const timeWithTimezone = time;
    // Convert time to match lastVisitTime of database (-7hours)
    const timeWithTimezoneDisplay = new Date(timeWithTimezone.setUTCHours((timeWithTimezone.getUTCHours() - 7) + timezone.offset))
    timeWithTimezoneDisplay.setUTCMinutes(time.getUTCMinutes() + timezone.offset % 1 * 60);
    return (
      <ResourceItem onClick={() => { }} id={blockItem.id.toString()}>
        {blockItem.id === 0 ? (
          <div className="table-content header d-flex">
            <div style={{ width: configWidthColTable.ipBlocked }} className="d-flex">
              <Text as="h6" variant="headingMd">
                IP Blocked
              </Text>
            </div>

            <div style={{ width: configWidthColTable.country }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Country
              </Text>
            </div>

            <div style={{ width: configWidthColTable.province }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Province
              </Text>
            </div>

            <div
              className="d-flex"
              style={{ width: configWidthColTable.totalAccess, alignItems: 'center', justifyContent: 'center' }}
              onClick={() => updateSort('totalAccess')}
            >
              <Text as="h6" variant="headingMd">
                Total Access
              </Text>
              <Icon
                source={
                  // eslint-disable-next-line no-nested-ternary
                  blockHistoryTable.sortBy === 'totalAccess' ? handleIconSort(blockHistoryTable.sort) : SortMinor
                }
                color={blockHistoryTable.sortBy === 'totalAccess' ? 'primary' : 'base'}
              />
            </div>

            <div style={{ width: configWidthColTable.lastestTime, flexDirection: 'column' }} className="d-flex" onClick={() => updateSort('lastVisitedTime')}>
              <div className="d-flex" onClick={() => updateSort('lastVisitedTime')}>
                <Text as="h6" variant="headingMd">
                  Lastest time
                </Text>
                <Icon
                  source={
                    // eslint-disable-next-line no-nested-ternary
                    blockHistoryTable.sortBy === 'lastVisitedTime' ? handleIconSort(blockHistoryTable.sort) : SortMinor
                  }
                  color={blockHistoryTable.sortBy === 'lastVisitedTime' ? 'primary' : 'base'}
                />
              </div>
              <Text variant="headingXs" as="h6">
                {timezone?.label?.split(' ')[0]}
              </Text>
            </div>
          </div>
        ) : (
          <div className="table-content d-flex">
            <div style={{ width: configWidthColTable.ipBlocked }}>
              {blockItem.ip?.length > 20 ? (
                <Tooltip dismissOnMouseOut content={blockItem.ip}>
                  <Text as="h6" variant="bodyMd">
                    {`${blockItem.ip.slice(0, 20)}...`}
                  </Text>
                </Tooltip>
              ) : (
                <Text as="h6" variant="bodyMd">
                  {blockItem.ip}
                </Text>
              )}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.country, alignItems: 'center' }}>
              <ReactCountryFlag svg className="emojiFlag" countryCode={blockItem.countryCode} style={{ marginRight: '4px' }} />
              <Text as="h6" variant="bodyMd">
                {blockItem.countryName}
              </Text>
            </div>
            <div style={{ width: configWidthColTable.province }}>
              <Text as="h6" variant="bodyMd">
                <p>{blockItem.cityName}</p>
              </Text>
            </div>
            <div
              className="d-flex"
              style={{ width: configWidthColTable.totalAccess, alignItems: 'center', justifyContent: 'center' }}
            >
              {blockItem.totalAccess}
            </div>
            <div style={{ width: configWidthColTable.lastestTime }}>
              {moment(timezone?.offset ? timeWithTimezoneDisplay : blockItem.lastVisitedTime).format('MMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
        )}
      </ResourceItem>
    );
  };

  const handleExportHistory = () => {
    window.open(`${process.env.REACT_APP_API_END_POINT}visitor/block/export?instance=${config.instance}`);
  };

  return (
    <div>
      <Card
        title="Blocked history"
        actions={{ content: 'Export', buttonProps: { size: 'medium', icon: ImportMinor }, onAction: handleExportHistory }}
      >
        <ResourceList
          resourceName={resourceName}
          items={data && data.listIp?.length > 0 ? [headerTable, ...data.listIp] : []}
          renderItem={RenderItem}
          loading={isLoading}
          filterControl={filterControl}
          emptyState={emptyStateMarkup}
        />
        <div className="mt-16">
          <Pagination
            label={
              data?.listIp?.length
                ? `Showing ${(blockHistoryTable.page - 1) * Number(blockHistoryTable.perPage) + 1} to ${Math.min(
                  blockHistoryTable.page * Number(blockHistoryTable.perPage),
                  data?.meta.totalItems,
                )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1}
            onPrevious={() => {
              dispatch(
                dashboardSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page - 1,
                }),
              );
            }}
            hasNext={
              data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(blockHistoryTable.perPage))
            }
            onNext={() => {
              dispatch(
                dashboardSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page + 1,
                }),
              );
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default TableBlockedHistory;
