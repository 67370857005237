import React, { useCallback, useState } from 'react';
import { Combobox, Icon, LegacyStack, Listbox, Modal, ModalProps, Select, Tag, Text, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { handleToastMuatation, listCountry, validateUrl } from '@/helpers';
import { Enum } from '@/constants';
import blockListSlice, { inputIspSelector, selectTypeSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { SearchMinor } from '@shopify/polaris-icons';
import { IResponseApi } from '@/types/api/response.api';
import { debounce } from 'lodash';

function ModalBlockISP({ ...props }: ModalProps) {
  const dispatch = useDispatch();
  const typeRule = useSelector(selectTypeSelector);
  const setting = useSelector(settingSelector);
  const inputIsp = useSelector(inputIspSelector);
  const [addBlackList, addBlackListStatus] = apiCaller.useAddBlackListMutation();
  const [addWhiteList, addWhiteListStatus] = apiCaller.useAddWhiteListMutation();
  const [getListIsp, { isFetching }] = apiCaller.useLazyGetListISPQuery();
  const [state, setState] = useState({
    selectedCountry: '',
  });
  const [selectedOptions, setSelectedOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [listIsp, setListIsp] = useState<Array<IResponseApi.ListIsp>>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAutoComplete = useCallback(
    debounce((value: string, country: string) => {
      getListIsp({ countryCode: country, search: value }).then((res) => {
        const resultOptions = res.data?.listIsp.filter((option) => option.asName);
        setListIsp(resultOptions);
      });
    }, 1000),
    [],
  );
  const updateText = (value: string) => {
    debounceAutoComplete(value, state.selectedCountry);
    dispatch(blockListSlice.actions.handleInputIsp(value));
  };

  const updateSelection = (valueSelected: string) => {
    const listValues = selectedOptions.map((value) => value.value);
    if (listValues.includes(valueSelected)) {
      setSelectedOptions(selectedOptions.filter((option) => option.value !== valueSelected));
    } else {
      const listSelected = [...selectedOptions];
      const selected = listIsp?.find((isp) => isp.asCode === valueSelected);
      if (selected) {
        listSelected.push({
          label: selected.asName,
          value: selected.asCode,
        });
        setSelectedOptions(listSelected);
      }
    }
  };

  const removeTag = useCallback(
    (tag: string) => () => {
      const options = [...selectedOptions];
      const index = options.findIndex((option) => option.value === tag);
      options.splice(index, 1);
      setSelectedOptions(options);
    },
    [selectedOptions],
  );

  const tagsMarkup = selectedOptions.map((option) => (
    <Tag key={`option-${option}`} onRemove={removeTag(option.value)}>
      {option.label}
    </Tag>
  ));

  const optionsMarkup = listIsp
    ? listIsp.map((option) => {
      const { asCode, asName } = option;
      return (
        <Listbox.Option
          key={asCode}
          value={asCode}
          selected={selectedOptions.map((item) => item.value).includes(asCode)}
          accessibilityLabel={asName}
        >
          {asName}
        </Listbox.Option>
      );
    })
    : null;
  const handleDisableBtn = (): boolean => {
    const caseListCountry = selectedOptions.length === 0;
    const caseRedirect =
      typeRule === Enum.ActionType.Redirect &&
      (!setting.itemSelected.linkRedirect ||
        (!!setting.itemSelected.linkRedirect && !validateUrl(setting.itemSelected.linkRedirect)));
    return caseListCountry || caseRedirect;
  };
  const handleChangeRedirect = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, linkRedirect: content },
      }),
    );
  };
  const handleChangeDisplayName = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, shortUrl: content },
      }),
    );
  };
  const handleClearAll = () => {
    setSelectedOptions([]);
    dispatch(blockListSlice.actions.handleInputIsp(''));
  };
  const handleChangeValue = (value: string) => {
    dispatch(
      blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: {
          ...setting.itemSelected,
          country: [value],
        },
      }),
    );
    getListIsp({
      countryCode: value,
      search: '',
    }).then((res) => {
      setListIsp(res.data?.listIsp);
    });
    dispatch(blockListSlice.actions.clearInputIsp());
    setState({ ...state, selectedCountry: value });
    setSelectedOptions([]);
  };
  const handleAddBlackList = () => {
    const saveBlackList = addBlackList;
    if (typeRule === Enum.ActionType.Redirect) {
      saveBlackList({
        priority: setting.itemSelected.priority,
        id: setting.itemSelected.id,
        criteria: Enum.CriteriaType.ISP,
        type: Enum.ActionType.Redirect,
        linkRedirect: setting.itemSelected.linkRedirect,
        shortUrl: setting.itemSelected.shortUrl,
        country: setting.itemSelected.country,
        ispCode: selectedOptions.map((item) => item.value),
      }).then((res) => {
        props.onClose();
        dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      });
    } else {
      saveBlackList({
        priority: setting.itemSelected.priority,
        id: setting.itemSelected.id,
        criteria: Enum.CriteriaType.ISP,
        type: Enum.ActionType.Block,
        country: setting.itemSelected.country,
        ispCode: selectedOptions.map((item) => item.value),
      }).then((res) => {
        props.onClose();
        dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      });
    }
    dispatch(blockListSlice.actions.clearSetting());
  };
  const handleAddWhiteList = () => {
    const saveWhiteList = addWhiteList;
    saveWhiteList({
      priority: setting.itemSelected.priority,
      id: setting.itemSelected.id,
      criteria: Enum.CriteriaType.ISP,
      country: setting.itemSelected.country,
      ispCode: selectedOptions.map((item) => item.value),
    }).then((res) => {
      props.onClose();
      dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
    });
    dispatch(blockListSlice.actions.clearSetting());
  };
  return (
    <Modal
      {...props}
      large
      open={props.open}
      onClose={props.onClose}
      title={
        <div>
          {typeRule === Enum.ActionType.Block ? 'Add ISP to block' : null}
          {typeRule === Enum.ActionType.Redirect ? 'Add ISP to redirect' : null}
          {typeRule === '0' ? 'Add ISP to whitelist' : null}
        </div>
      }
      primaryAction={{
        content: 'Add',
        onAction: typeRule === '0' ? handleAddWhiteList : handleAddBlackList,
        disabled: handleDisableBtn(),
        loading: addBlackListStatus.isLoading || addWhiteListStatus.isLoading,
      }}
      secondaryActions={[
        {
          content: 'Clear All',
          onAction: handleClearAll,
        },
      ]}
    >
      <Modal.Section>
        {typeRule === Enum.ActionType.Redirect ? (
          <LegacyStack vertical spacing="baseTight">
            <TextField
              label="Redirect to"
              value={setting.itemSelected.linkRedirect}
              onChange={handleChangeRedirect}
              autoComplete="off"
              prefix="https://"
              requiredIndicator
            />
            {!!setting.itemSelected.linkRedirect && !validateUrl(setting.itemSelected.linkRedirect) ? (
              <Text as={'h6'} variant={'headingXs'} color="critical">
                Please enter a valid URL
              </Text>
            ) : null}
            <TextField
              label="Displayed name (optional)"
              maxLength={32}
              value={setting.itemSelected.shortUrl}
              onChange={handleChangeDisplayName}
              autoComplete="off"
              helpText="This name is displayed instead of the full URL to save space."
            />
            <Select
              label="Country"
              options={listCountry()}
              onChange={handleChangeValue}
              value={setting.itemSelected.country[0]}
              requiredIndicator
              placeholder="Pick a country"
            />
            <Combobox
              allowMultiple
              activator={
                <Combobox.TextField
                  prefix={<Icon source={SearchMinor} />}
                  onChange={updateText}
                  value={inputIsp}
                  label="ISP name"
                  placeholder="Pick an ISP"
                  disabled={isFetching}
                  requiredIndicator
                  autoComplete="off"
                />
              }
            >
              {listIsp ? <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox> : null}
            </Combobox>
            <div>
              <LegacyStack>{tagsMarkup}</LegacyStack>
            </div>
          </LegacyStack>
        ) : (
          <LegacyStack vertical spacing="baseTight">
            <Select
              label="Country"
              options={listCountry()}
              onChange={handleChangeValue}
              value={setting.itemSelected.country[0]}
              requiredIndicator
              placeholder="Pick a country"
            />
            <Combobox
              allowMultiple
              activator={
                <Combobox.TextField
                  prefix={<Icon source={SearchMinor} />}
                  onChange={updateText}
                  disabled={isFetching}
                  value={inputIsp}
                  label="ISP name"
                  placeholder="Pick an ISP"
                  requiredIndicator
                  autoComplete="off"
                />
              }
            >
              {listIsp ? <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox> : null}
            </Combobox>
            <div>
              <LegacyStack>{tagsMarkup}</LegacyStack>
            </div>
          </LegacyStack>
        )}
      </Modal.Section>
    </Modal>
  );
}

export default ModalBlockISP;
