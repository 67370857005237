import React from 'react';
import { LegacyStack, Modal, ModalProps, TextField, Text } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { selectTypeSelector, settingSelector } from '@/redux/slice/blockList.slice';
import { handleToastMuatation, validateIp, validateUrl } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { Enum } from '@/constants';
import slice from '@/redux/slice';

function ModalBlockIPAddress({ ...props }: ModalProps) {
  const dispatch = useDispatch();
  const [addBlackList, addBlackListStatus] = apiCaller.useAddBlackListMutation();
  const [addWhiteList, addWhiteListStatus] = apiCaller.useAddWhiteListMutation();
  const typeRule = useSelector(selectTypeSelector);
  const setting = useSelector(settingSelector);
  const handleChangeValue = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, ipAddress: content },
      }),
    );
  };
  const handleChangeRedirect = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, linkRedirect: content },
      }),
    );
  };
  const handleChangeDisplayName = (content: string) => {
    dispatch(
      slice.blockListSlice.actions.handleSetting({
        ...setting,
        itemSelected: { ...setting.itemSelected, shortUrl: content },
      }),
    );
  };
  const handleDisableBtn = (): boolean => {
    const caseIpAddress = !setting.itemSelected.ipAddress || !validateIp(setting.itemSelected.ipAddress);
    const caseRedirect =
      typeRule === Enum.ActionType.Redirect &&
      (!setting.itemSelected.linkRedirect ||
        (!!setting.itemSelected.linkRedirect && !validateUrl(setting.itemSelected.linkRedirect)));
    return caseIpAddress || caseRedirect;
  };
  const handleAddBlackList = () => {
    const saveBlackList = addBlackList;
    if (typeRule === Enum.ActionType.Redirect) {
      saveBlackList({
        priority: setting.itemSelected.priority,
        id: setting.itemSelected.id,
        criteria: Enum.CriteriaType.IpAddress,
        type: Enum.ActionType.Redirect,
        ipAddress: setting.itemSelected.ipAddress,
        linkRedirect: setting.itemSelected.linkRedirect,
        shortUrl: setting.itemSelected.shortUrl,
      }).then((res) => {
        props.onClose();
        dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      });
    } else {
      saveBlackList({
        priority: setting.itemSelected.priority,
        id: setting.itemSelected.id,
        criteria: Enum.CriteriaType.IpAddress,
        type: Enum.ActionType.Block,
        ipAddress: setting.itemSelected.ipAddress,
      }).then((res) => {
        props.onClose();
        dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      });
    }
  };
  const handleAddWhiteList = () => {
    const saveWhiteList = addWhiteList;
    saveWhiteList({
      priority: setting.itemSelected.priority,
      id: setting.itemSelected.id,
      criteria: setting.itemSelected.criteria,
      ipAddress: setting.itemSelected.ipAddress,
      note: setting.itemSelected.note,
    }).then((res) => {
      props.onClose();
      dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
    });
  };
  return (
    <div>
      <Modal
        {...props}
        large
        primaryAction={{
          content: 'Add',
          onAction: typeRule === '0' ? handleAddWhiteList : handleAddBlackList,
          disabled: handleDisableBtn(),
          loading: addBlackListStatus.isLoading || addWhiteListStatus.isLoading,
        }}
      >
        <Modal.Section>
          {typeRule === Enum.ActionType.Redirect ? (
            <LegacyStack vertical spacing="baseTight">
              <TextField
                label="Redirect to"
                value={setting.itemSelected.linkRedirect}
                onChange={handleChangeRedirect}
                autoComplete="off"
                prefix="https://"
                requiredIndicator
              />
              {!!setting.itemSelected.linkRedirect && !validateUrl(setting.itemSelected.linkRedirect) ? (
                <Text as={'h6'} variant={'headingXs'} color="critical">
                  Please enter a valid URL
                </Text>
              ) : null}
              <TextField
                label="Displayed name (optional)"
                maxLength={32}
                value={setting.itemSelected.shortUrl}
                onChange={handleChangeDisplayName}
                autoComplete="off"
                helpText="This name is displayed instead of the full URL to save space."
              />
              <TextField
                label="Enter IP address"
                value={setting.itemSelected.ipAddress}
                onChange={handleChangeValue}
                autoComplete="off"
                helpText="Example of a valid IP address: 177.229.213.89"
                requiredIndicator
              />
            </LegacyStack>
          ) : (
            <TextField
              label="Enter IP address"
              value={setting.itemSelected.ipAddress}
              onChange={handleChangeValue}
              autoComplete="off"
              helpText="Example of a valid IP address: 177.229.213.89"
              requiredIndicator
            />
          )}
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default ModalBlockIPAddress;
