/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from "react";
import { Text } from "@shopify/polaris";
import { images } from "@/asset";

const StepOne = () => {
    return (
        <div className="tab-pane">
            <div className="welcome-title">
                <Text as="h3" variant="headingMd">
                    Welcome to Blockify!
                </Text>
            </div>
            <div className="panel-body">
                <div className="image-center">
                    <img
                        src={images.illustration}
                        alt="Responsive image"
                    />
                </div>
                <div className="welcome-question">
                    <Text as="h3" variant="headingMd">
                        Ready to protect your website?
                    </Text>
                </div>
                <p className="welcome-description" style={{ fontSize: "16px" }}>
                    <Text as="h3" variant="bodyMd" >
                        We’ve created this quick start guide to help you get ready in just a few clicks!
                    </Text>
                </p>
            </div>
        </div>
    );
};

export default memo(StepOne);
