import { PATH } from '@/constants';
import BlockList from '@/pages/block-list';
import Dashboard from '@/pages/dashboard';
import Settings from '@/pages/settings';
import Analytics from '@/pages/analytics';
import FullScreen from '@/pages/settings/components/customTemplate/fullScreen';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Welcome from '@/pages/welcome';

interface IRouter {
  path: string;
  element: React.ReactNode;
}

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: <Dashboard />,
  },
  {
    path: PATH.BLOCKLIST.pathname,
    element: <BlockList />,
  },
  {
    path: PATH.DASHBOARD.pathname,
    element: <Dashboard />,
  },
  {
    path: PATH.ANALYTICS.pathname,
    element: <Analytics />,
  },
  {
    path: PATH.SETTINGS.pathname,
    element: <Settings />,
  },
  {
    path: PATH.CUSTOMTEMPLATE.pathname,
    element: <FullScreen />,
  },
  {
    path: PATH.WELCOME.pathname,
    element: <Welcome />,
  },
];
function RenderRouter(): JSX.Element {
  return (
    <Routes>
      {router.map((item: IRouter) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
    </Routes>
  );
}
export default RenderRouter;
