import Layout from '@/components/layout';
import { config } from '@/config';
import { Enum } from '@/constants';
import options from '@/constants/options';
import { apiCaller } from '@/redux/query';
import blockListSlice, { selectTypeSelector, settingSelector, tabSelectedSelector } from '@/redux/slice/blockList.slice';
import { Button, Columns, Icon, LegacyCard, Select, Tabs, Text, Tooltip } from '@shopify/polaris';
import { CircleChevronDownMinor, CircleChevronRightMinor } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalBlockCountry from './components/ModalBlockCountry';
import ModalBlockIPAddress from './components/ModalBlockIPAddress';
import ModalBlockIPRange from './components/ModalBlockIPRange';
import ModalBlockISP from './components/ModalBlockISP';
import ModalBlockState from './components/ModalBlockState';
import TableBlackList from './components/TableBlackList';
import TableWhiteList from './components/TableWhiteList';
import { BlockListStyled } from './styled';
import slice from '@/redux/slice';
import { CriteriaType } from '@/constants/enum';

const selectedRuleConfig = [
  {
    value: Enum.ActionType.Block,
    label: 'Block',
  },
  {
    value: Enum.ActionType.Redirect,
    label: 'Redirect',
  },
  {
    value: '0',
    label: 'Whitelist',
  },
];

const tabsConfig = [
  {
    id: 'black-list',
    content: 'Blacklist',
    panelID: 'black-list',
    children: <TableBlackList />,
  },
  {
    id: 'white-list',
    panelID: 'white-list',
    content: 'Whitelist',
    children: <TableWhiteList />,
  },
];

const BlockList = (): JSX.Element => {
  const dataSettings = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const setting = useSelector(settingSelector);
  const tabSelected = useSelector(tabSelectedSelector);
  const selectedRule = useSelector(selectTypeSelector);
  const dispatch = useDispatch();
  const [modalKey, setModalKey] = useState<CriteriaType | '-1'>('-1');
  const handleOnClose = () => {
    setModalKey('-1');
    dispatch(blockListSlice.actions.clearSetting());
  };

  const renderTitleModal = (rule: string) => {
    const label = selectedRuleConfig.find((item) => item.value === rule)?.label;
    const type = options.configRules.find((item) => item.id === modalKey)?.description;
    return `Add ${type} to ${label}`;
  };
  const modalNumber = [
    {
      id: Enum.CriteriaType.IpAddress,
      content: (
        <ModalBlockIPAddress
          open={modalKey === Enum.CriteriaType.IpAddress}
          title={renderTitleModal(selectedRule)}
          onClose={handleOnClose}
        />
      ),
    },
    {
      id: Enum.CriteriaType.IpAddressStartWith,
      content: (
        <ModalBlockIPRange
          open={modalKey === Enum.CriteriaType.IpAddressStartWith}
          title={renderTitleModal(selectedRule)}
          onClose={handleOnClose}
        />
      ),
    },
    {
      id: Enum.CriteriaType.Country,
      content: (
        <ModalBlockCountry
          open={modalKey === Enum.CriteriaType.Country}
          title={renderTitleModal(selectedRule)}
          onClose={handleOnClose}
        />
      ),
    },
    {
      id: Enum.CriteriaType.Province,
      content: (
        <ModalBlockState
          open={modalKey === Enum.CriteriaType.Province}
          title={renderTitleModal(selectedRule)}
          onClose={handleOnClose}
        />
      ),
    },
    {
      id: Enum.CriteriaType.ISP,
      content: (
        <ModalBlockISP open={modalKey === Enum.CriteriaType.ISP} title={renderTitleModal(selectedRule)} onClose={handleOnClose} />
      ),
    },
  ];
  const [isOpenRule, setIsOpenRule] = useState(true);
  const handleSelectChange = (value: string) => {
    if (value === '0') {
      dispatch(slice.blockListSlice.actions.handleTabSelected(1));
      dispatch(
        blockListSlice.actions.handleSetting({
          ...setting,
          itemSelected: { ...setting.itemSelected, priority: Enum.ListType.WhiteList },
        }),
      );
    } else {
      dispatch(slice.blockListSlice.actions.handleTabSelected(0));
      dispatch(
        blockListSlice.actions.handleSetting({
          ...setting,
          itemSelected: { ...setting.itemSelected, priority: Enum.ListType.BlackList },
        }),
      );
    }
    dispatch(blockListSlice.actions.handleSelectedRule(value));
  };
  const handleModalClick = (id: CriteriaType) => {
    setModalKey(id);
  };
  const renderModal = () => {
    return <div>{modalNumber.find((item) => item.id === modalKey)?.content}</div>;
  };
  return (
    <Layout
      layoutProps={{
        title: 'Blocker & Redirector ',
      }}
    >
      <BlockListStyled>
        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
          <div className="d-flex add-rule-title">
            {isOpenRule ? (
              <Button
                outline
                icon={<Icon source={CircleChevronDownMinor} color="base" />}
                onClick={() => {
                  setIsOpenRule(!isOpenRule);
                }}
              />
            ) : (
              <Button
                outline
                icon={<Icon source={CircleChevronRightMinor} color="base" />}
                onClick={() => {
                  setIsOpenRule(!isOpenRule);
                }}
              />
            )}
            <div className="mr-8 ml-8">
              <Text as={'h3'} variant={'headingSm'}>
                Add new item to
              </Text>
            </div>
            <Select label="" labelHidden options={options.listTypeOptions} onChange={handleSelectChange} value={selectedRule} />
          </div>
        </div>
        <div className="mt-16" style={{ height: isOpenRule ? '90px' : 0, transition: 'all 0.5s', overflow: 'hidden' }}>
          <Columns columns={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 5 }} gap={{ xl: '3', lg: '3', md: '3', sm: '3', xs: '3' }}>
            {options.configRules.map((item, index) => {
              return item.condition?.includes(dataSettings?.data?.settings?.user.plan || '') ? (
                <Tooltip key={index} content={item.tooltip}>
                  <div
                    key={index}
                    className={`${
                      item.condition?.includes(dataSettings.data?.settings.user.plan || '')
                        ? 'add-rule-container div-disable'
                        : 'add-rule-container'
                    }`}
                    onClick={() => handleModalClick(item.id)}
                  >
                    <img className="add-rule-img" src={item.src} alt={item.description} />
                    <Text as="h6" variant="headingXs">
                      {`${selectedRuleConfig.find((item) => item.value === selectedRule)?.label} ${item.description}`}
                    </Text>
                  </div>
                </Tooltip>
              ) : (
                <div
                  key={index}
                  className={`${
                    item.condition?.includes(dataSettings.data?.settings.user.plan || '')
                      ? 'add-rule-container div-disable'
                      : 'add-rule-container'
                  }`}
                  onClick={() => handleModalClick(item.id)}
                >
                  <img className="add-rule-img" src={item.src} alt={item.description} />
                  <Text as="h6" variant="headingXs">
                    {`${selectedRuleConfig.find((item) => item.value === selectedRule)?.label} ${item.description}`}
                  </Text>
                </div>
              );
            })}
          </Columns>
        </div>
        {renderModal()}
        <div className="mt-16">
          <LegacyCard>
            <Tabs
              tabs={tabsConfig}
              selected={tabSelected}
              onSelect={(tabIndex) => {
                dispatch(slice.blockListSlice.actions.handleTabSelected(tabIndex));
                if (tabIndex) {
                  handleSelectChange('0');
                } else {
                  handleSelectChange('1');
                }
              }}
              fitted
            >
              <LegacyCard.Section>{tabsConfig[tabSelected].children}</LegacyCard.Section>
            </Tabs>
          </LegacyCard>
        </div>
      </BlockListStyled>
    </Layout>
  );
};
export default memo(BlockList);
