import { Enum } from '@/constants';
import options from '@/constants/options';
import { handleToastMuatation, listCountry, otherCountry } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { Autocomplete, Button, Modal, Select, TextField } from '@shopify/polaris';
import { State } from 'country-state-city';
import { memo, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import slice from '@/redux/slice';

interface Props {
  onClose: () => void;
  open: boolean;
  id: number;
  priority: string;
  actionType: string;
  criteria: string;
  value: {
    address: string;
    ipAddressStartWith: string;
    country: string;
    city: string;
    ispCode: string;
    linkRedirect: string;
    shortUrl: string;
  };
}

const listProvince = (country: string) => {
  const province = State?.getStatesOfCountry(country).map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });
  const listProvince = province.filter((item) => item.label !== 'Taiwan' && item.label !== 'Hong Kong SAR');
  return listProvince;
};

const ModalEditRule = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const [updateRule, updateRuleStatus] = apiCaller.useUpdateSettingMutation();
  const [ispInput, setIspInput] = useState('');
  const [listIsp, setListIsp] = useState<Array<{ label: string; value: string }>>([]);
  const [getListIsp] = apiCaller.useLazyGetListISPQuery();
  const [state, setState] = useState<{
    address: string;
    country: string;
    city: string;
    ispCode: string;
    linkRedirect: string;
    shortUrl: string;
  }>({
    address: '',
    country: '',
    city: '',
    ispCode: '',
    linkRedirect: '',
    shortUrl: '',
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAutoComplete = useCallback(
    debounce((value: string, country: string) => {
      getListIsp({ countryCode: country, search: value }).then((res) => {
        const options = res.data?.listIsp.map((item) => {
          return {
            label: item.asName,
            value: item.asCode,
          };
        });
        setListIsp(options || []);
      });
    }, 1000),
    [],
  );

  const handleDisableBtn = useCallback(() => {
    const caseState = props.criteria === Enum.CriteriaType.Province && !state.city;
    const caseISP = props.criteria === Enum.CriteriaType.ISP && !state.ispCode;
    return caseState || caseISP;
  }, [props.criteria, state.city, state.ispCode]);

  useEffect(() => {
    if (props.open) {
      getListIsp({ countryCode: props.value.country }).then((res) => {
        const options = res.data?.listIsp.map((item) => {
          return {
            label: item.asName,
            value: item.asCode,
          };
        });
        setIspInput(res.data?.listIsp.find((item) => item.asCode === props.value.ispCode)?.asName || '');
        setListIsp(options || []);
        setState({
          ...props.value,
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.open]);
  const handleOnchange =
    (key: 'address' | 'collectionId' | 'linkRedirect' | 'country' | 'city' | 'ispCode' | 'shortUrl') => (value: string) => {
      if (key === 'country' && (props.criteria === Enum.CriteriaType.Province || props.criteria === Enum.CriteriaType.ISP)) {
        getListIsp({ countryCode: value }).then((res) => {
          const options = res.data?.listIsp.map((item) => {
            return {
              value: item.asCode,
              label: item.asName,
            };
          });
          setListIsp(options || []);
        });
        setState({
          ...state,
          country: value,
          city: '',
          ispCode: '',
        });
        setIspInput('');
      } else {
        setState({
          ...state,
          [key]: value,
        });
      }
    };
  const textField = (
    <Autocomplete.TextField
      onChange={(value) => {
        debounceAutoComplete(value, state.country);
        setIspInput(value);
      }}
      label="ISP name"
      value={ispInput}
      autoComplete="off"
    />
  );

  const handleSave = () => {
    updateRule({
      criteria: props.criteria,
      priority: props.priority,
      type: props.actionType,
      city: state.city,
      country: state.country,
      id: props.id,
      ipAddress: state.address,
      ispCode: state.ispCode,
      linkRedirect: state.linkRedirect,
      shortUrl: state.shortUrl ? state.shortUrl.trim() : '',
    }).then((res) => {
      dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
      props.onClose();
    });
  };

  const Footer = () => {
    return (
      <div className="mt-16" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button onClick={props.onClose}>Cancel</Button>
        <div className="ml-8">
          <Button loading={updateRuleStatus.isLoading} primary onClick={handleSave} disabled={handleDisableBtn()}>
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal onClose={props.onClose} open={props.open} title="Edit rule">
      <div className="pd-20">
        <Select label="List type" options={options.typeList} disabled value={props.priority} />
        {props.priority === Enum.ListType.BlackList ? (
          <Select label="Block or Redirect" options={options.blockTypeOptions} disabled value={props.actionType} />
        ) : null}
        <Select label="Criteria" options={options.criteriaFilters} disabled value={props.criteria} />

        {props.actionType === Enum.ActionType.Redirect ? (
          <div>
            <TextField
              label="Redirect to"
              value={state.linkRedirect}
              onChange={handleOnchange('linkRedirect')}
              autoComplete="off"
              prefix="https://"
              requiredIndicator
            />
            <TextField
              label="Displayed name (optional)"
              maxLength={32}
              value={state.shortUrl}
              onChange={handleOnchange('shortUrl')}
              autoComplete="off"
              helpText="This name is displayed instead of the full URL to save space."
            />
          </div>
        ) : null}

        {props.criteria === Enum.CriteriaType.IpAddress || props.criteria === Enum.CriteriaType.IpAddressStartWith ? (
          <TextField autoComplete="off" label="Enter IP address" value={state?.address} onChange={handleOnchange('address')} />
        ) : null}

        {props.criteria === Enum.CriteriaType.Country ||
        props.criteria === Enum.CriteriaType.Province ||
        props.criteria === Enum.CriteriaType.ISP ? (
          <Select
            options={props.criteria === Enum.CriteriaType.Country ? [...otherCountry, ...listCountry()] : listCountry()}
            label="Country name"
            value={state?.country}
            onChange={handleOnchange('country')}
          />
        ) : null}
        {props.criteria === Enum.CriteriaType.Province ? (
          <Select
            options={listProvince(state.country)}
            label="State/Province/City name"
            value={state.city}
            onChange={handleOnchange('city')}
          />
        ) : null}
        {props.criteria === Enum.CriteriaType.ISP ? (
          <Autocomplete
            options={listIsp}
            selected={[ispInput]}
            onSelect={(value) => {
              const isp = listIsp.find((item) => item.value === value[0]);
              if (isp) {
                setIspInput(isp.label);
                setState({ ...state, ispCode: value[0] });
              }
            }}
            textField={textField}
          />
        ) : null}
        <Footer />
      </div>
    </Modal>
  );
};

export default memo(ModalEditRule);
