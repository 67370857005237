import i18n from '@/i18n/en.json';
import RenderRouter from '@/routes';
import { AppProvider } from '@shopify/polaris';
import { useEffect } from 'react';
import './App.css';
import { hotjar } from 'react-hotjar';
import Review from './pages/Review';

function App() {
  useEffect(() => {
    const endPoint = process.env.REACT_APP_API_END_POINT;
    const script = document.createElement('script');
    script.src = `${endPoint}file/crisp`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [])
  // const history = createBrowserHistory();
  // const location = useLocation();
  useEffect(() => {
    hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 6);
  }, [])
  return (
    <AppProvider i18n={i18n}>
      {/* Nếu như bạn đã có AppBridgeProvider config, hãy bỏ cmt ClientRouter , RoutePropagator,history và location */}
      {/* <ClientRouter history={history} /> */}
      {/* <RoutePropagator location={location} /> */}
      <RenderRouter />
      <Review />
    </AppProvider>
  );
}

export default App;
