import { memo, useState } from 'react';

import { DropZone, Toast } from '@shopify/polaris';
import { ISettings } from '@/types/components';

const maxSize = 2000000;
function CustomImage({ ...props }: ISettings.CustomImage) {
  const [configToast, setConfigToast] = useState({
    isOpen: false,
    message: '',
  });
  const handleDropZoneDrop = async (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    if (validImageTypes.includes(acceptedFiles[0].type)) {
      props.handleUpload(acceptedFiles[0], window.URL.createObjectURL(acceptedFiles[0]));
    }
  };

  const validateFile = (file: any) => {
    if (file.size > maxSize) {
      setConfigToast({
        isOpen: true,
        message: 'File size too large (more than 2MB)',
      });
    }
    if (!validImageTypes.includes(file.type)) {
      setConfigToast({
        isOpen: true,
        message: 'Unsupported file format. We only supported formats: JPG, JPEG, GIF, PNG, and PDF',
      });
    }
    return file.size < maxSize && validImageTypes.includes(file.type);
  };

  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const actionHint = 'Supported formats: JPG, JPEG, PNG\nMaximum up to 2MB';
  const fileUpload = <DropZone.FileUpload actionHint={actionHint} actionTitle="Choose file" />;

  return (
    <div>
      {configToast.isOpen ? (
        <Toast
          content={configToast.message}
          onDismiss={() => {
            setConfigToast({
              isOpen: false,
              message: '',
            });
          }}
        />
      ) : null}
      <DropZone customValidator={validateFile} label="" labelHidden onDrop={handleDropZoneDrop} variableHeight>
        {fileUpload}
      </DropZone>
    </div>
  );
}

export default memo(CustomImage);
