/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { Button, LegacyCard, LegacyStack, Modal, TextContainer, Text, Loading, Link } from '@shopify/polaris';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';
import CustomSettingToggle from '@/components/settingToggle';
import { DuplicateMinor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
import slice from '@/redux/slice';
import { useDispatch } from 'react-redux';
import toastSlice from '@/redux/slice/toast.slice';
import { handleToastMuatation } from '@/helpers';

function GeneralSettings() {
    const dispatch = useDispatch();
    const { data, isFetching } = apiCaller.useGetGeneralDetailQuery(config.instanceId);
    const adminURL = apiCaller.useGetAdminURLQuery(config.instanceId);
    const [enableApp, enableStatus] = apiCaller.useEnableAppMutation();
    const [timezone] = apiCaller.useSettingTimezoneMutation();
    const [state, setState] = useState({
        modalOpen: false,
        copied: false,
        showFullUrl: false,
        selectedTimeZone: localStorage.getItem('selectedTimeZone') || '',
        clickButton: '',
    });
    const handleSelectTimeZone = (timezone: ITimezoneOption) => {
        setState({ ...state, selectedTimeZone: JSON.stringify(timezone) })
    };
    const handleCopyUrl = () => {
        navigator.clipboard.writeText(adminURL?.data?.url || '')
            .then(() => {
                setState({ ...state, copied: true });
                dispatch(slice.toastSlice.actions.handleToast({
                    isOpen: true,
                    content: 'Copied',
                    error: false,
                }));
            })
    };

    const handleShowUrl = () => {
        setState({ ...state, showFullUrl: true });
    };

    const handleHideUrl = () => {
        setState({ ...state, showFullUrl: false });
    };

    const handleOpenModalEnableApp = () => {
        if (data?.settings.user.enableApp === true) {
            setState({ ...state, modalOpen: true });
        } else {
            enableApp(!data?.settings.user.enableApp).then((res) => {
                if ('data' in res && res.data.state === 0) {
                    dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
                } else {
                    dispatch(toastSlice.actions.handleToast({
                        isOpen: true,
                        content: 'Updated',
                        error: false,
                    }));
                }
            });
            setState({ ...state, clickButton: '1' })
        }
    };

    const handleConfirmToggle = () => {
        enableApp(!data?.settings.user.enableApp).then((res) => {
            if ('data' in res && res.data.state === 0) {
                dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
            } else {
                dispatch(toastSlice.actions.handleToast({
                    isOpen: true,
                    content: 'Updated',
                    error: false,
                }));
            }
        });
        setState({ ...state, modalOpen: false });
    };

    const handleCancelToggle = () => {
        setState({ ...state, modalOpen: false });
    };

    const handleOpenAdminAccess = () => {
        window.open(adminURL.data?.url);
    }

    useEffect(() => {
        if (state.selectedTimeZone) {
            timezone({ timezone: state.selectedTimeZone });
            localStorage.setItem('selectedTimeZone', state.selectedTimeZone);
        }
    }, [state.selectedTimeZone]);

    return (
        <div>
            <LegacyCard>
                <CustomSettingToggle
                    title='Enable Blockify'
                    enabled={data?.settings.user.enableApp ? true : false}
                    loading={(isFetching && state.clickButton === '1') || enableStatus.isLoading}
                    handleToggle={handleOpenModalEnableApp}
                />
            </LegacyCard>
            <LegacyCard title='Preferred time zone'>
                <LegacyCard.Section>
                    <TimezoneSelect
                        value={state.selectedTimeZone ? JSON.parse(state.selectedTimeZone).value : ''}
                        onChange={handleSelectTimeZone}
                        displayValue='UTC'
                    />
                </LegacyCard.Section>
            </LegacyCard>
            <LegacyCard title='Admin access URL'>
                <LegacyCard.Section>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                        <div style={{ maxWidth: '90%' }}>
                            {adminURL?.isLoading ? (
                                <Loading />
                            ) : (
                                <Link removeUnderline external onClick={handleOpenAdminAccess}>
                                    {state.showFullUrl ? adminURL.data?.url : `${adminURL.data?.url?.substring(0, 20)}***************` || ''}
                                </Link>
                            )}
                        </div>
                        <div style={{ maxWidth: '10%' }}>
                            <LegacyStack spacing='tight'>
                                <Button
                                    icon={state.showFullUrl ? ViewMinor : HideMinor}
                                    plain
                                    onClick={state.showFullUrl ? handleHideUrl : handleShowUrl}
                                ></Button>
                                <Button icon={DuplicateMinor} plain onClick={handleCopyUrl} />
                            </LegacyStack>
                        </div>
                    </div>
                    <Text as={'p'} variant={'headingXs'} color='subdued'>
                        Access via the above URL will not be blocked under any circumstances. Please keep the link disclosed.
                    </Text>
                </LegacyCard.Section>
            </LegacyCard>

            <Modal
                open={state.modalOpen}
                onClose={handleCancelToggle}
                title="Are you sure that you want to turn off Blockify?"
                primaryAction={{
                    content: 'Turn off',
                    onAction: handleConfirmToggle,
                    destructive: true,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleCancelToggle,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>
                            While turned off, Blockify <b>cannot protect your website</b> from unauthorized and fraudulent visitors. <b>Please make sure that you understand all the risks</b>. Do you still want to proceed?
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default GeneralSettings;
