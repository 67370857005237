/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import CustomDatePicker from '@/components/datePicker/DatePicker';
import Layout from '@/components/layout';
import { config } from '@/config';
import { Enum } from '@/constants';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { chartAnalyticsSelector, titleBtnDatePickerSelector, visitorLogSelector } from '@/redux/slice/visitorAnalytics.slice';
import { IPropsChart } from '@/types/components';
import { Button, LegacyCard, Columns, LegacyStack, EmptyState, Loading, Modal, Collapsible } from '@shopify/polaris';
import { RefreshMajor } from '@shopify/polaris-icons';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import HeatMap from './components/heatMap';
import TableVisitorLog from './components/table';
import { AnalyticsStyled } from './styled';

const SecondaryAction = (): JSX.Element => {
  const dispatch = useDispatch();
  const visitorLogTable = useSelector(visitorLogSelector);
  const chartData = useSelector(chartAnalyticsSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const [fetchVisitorList, fetchVisitorListStatus] = apiCaller.useLazyFetchVisitorListQuery();
  const [fetchChartAnalytics, fetchChartAnalyticsStatus] = apiCaller.useLazyFetchChartAnalyticsQuery();
  const handleRefreshClick = async () => {
    await Promise.all([
      fetchVisitorList({
        search: visitorLogTable.search,
        type: visitorLogTable.type || 'Other',
        risk: visitorLogTable.risk || 'safe',
        perPage: visitorLogTable.perPage[0],
        startDate: visitorLogTable.startDate,
        endDate: visitorLogTable.endDate,
        sortBy: visitorLogTable.sortBy,
        page: visitorLogTable.page,
        sort: visitorLogTable.sort,
      }),
      fetchChartAnalytics({
        startDate: chartData.startDate,
        endDate: chartData.endDate,
      }),
    ]);
  };
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(slice.visitorAnalyticsSlice.actions.handleVisitorLogTable({ ...visitorLogTable, startDate, endDate }));
    dispatch(slice.visitorAnalyticsSlice.actions.chartAnalytics({ ...chartData, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.visitorAnalyticsSlice.actions.handleTitleBtnDatePicker(title));
  };

  return (
    <LegacyStack>
      <Button
        onClick={handleRefreshClick}
        loading={fetchVisitorListStatus.isLoading && fetchChartAnalyticsStatus.isLoading}
        icon={RefreshMajor}
      >
        Refresh
      </Button>
      <CustomDatePicker
        titleButton={titleBtn}
        setTitleButton={onSaveTitleBtnDatePicker}
        startDate={visitorLogTable.startDate}
        endDate={visitorLogTable.endDate}
        onSave={onSaveDatePicker}
      />
    </LegacyStack>
  );
};

function Analytics() {
  const chartData = useSelector(chartAnalyticsSelector);
  const accountPlan = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const chartAnalytics = apiCaller.useFetchChartAnalyticsQuery({ ...chartData });
  const link = `https://www.wix.com/apps/upgrade/${process.env.REACT_APP_APP_ID}?appInstanceId=${accountPlan.data?.settings?.instanceId}`;
  const totalVisitorData = chartAnalytics.data?.data?.totalVisitorsChart?.data || [];
  const totalRiskData = chartAnalytics.data?.data?.totalRisksChart?.data || [];
  const totalSafesChart = chartAnalytics.data?.data?.totalSafesChart?.data || [];
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen((open) => !open);
  const handleOpenModal = () => setActive(!active);
  const totalVisitorChart: IPropsChart = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: chartAnalytics.data?.data?.totalVisitorsChart?.labels,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
    },

    series: [
      {
        name: 'Total Visitor',
        data: totalVisitorData,
      },
    ],
  };

  const totalRiskChart: IPropsChart = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: chartAnalytics.data?.data?.totalRisksChart?.labels,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
    },

    series: [
      {
        name: 'Total Risk IP',
        data: totalRiskData,
      },
    ],
  };

  const totalSafeIPChart: IPropsChart = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: chartAnalytics.data?.data?.totalSafesChart?.labels,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
    },

    series: [
      {
        name: 'Total Safe IP',
        data: totalSafesChart,
      },
    ],
  };

  return (
    <Layout
      layoutProps={{
        title: 'Visitor Analytics',
        fullWidth: true,
        secondaryActions: accountPlan.data?.settings.user.plan !== Enum.UserPlan.FREE ? <SecondaryAction /> : '',
      }}
    >
      {accountPlan.isLoading ? (
        <Loading />
      ) : accountPlan.data?.settings.user.plan === Enum.UserPlan.FREE ? (
        <LegacyCard sectioned>
          <EmptyState
            heading="Upgrade your subscription plan to use this function."
            footerContent={
              <LegacyStack spacing="baseTight">
                <Button onClick={handleToggle}>Learn More</Button>
                <Button primary url={link}>
                  Upgrade plan
                </Button>
              </LegacyStack>
            }
            image={images.lockIcon}
          >
            <p>This function is not available in your current subscription plan. Please upgrade to the Basic plan or higher.</p>
          </EmptyState>
          <div style={{ margin: '16px' }}>
            <Collapsible
              open={open}
              id="basic-collapsible"
              transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
              expandOnPrint
            >
              <img style={{ width: '100%' }} src={images.visitorScreenShot} alt="image" />
            </Collapsible>
          </div>
        </LegacyCard>
      ) : (
        <AnalyticsStyled>
          <Columns columns={{ xl: 3, lg: 3, md: 1, xs: 1, sm: 1 }} gap={{ lg: '4', xl: '4', md: '4', sm: '4', xs: '4' }}>
            <LegacyCard title={`Total visitors: ${chartAnalytics.data?.data?.totalVisit || 0}`}>
              <Chart
                options={totalVisitorChart.options}
                series={totalVisitorChart.series}
                type="line"
                width="100%"
                height={200}
              />
            </LegacyCard>
            <LegacyCard title={`Risk IP: ${chartAnalytics.data?.data?.totalRisk || 0}`}>
              <Chart options={totalRiskChart.options} series={totalRiskChart.series} type="line" width="100%" height={200} />
            </LegacyCard>
            <LegacyCard title={`Safe IP: ${chartAnalytics.data?.data?.totalSafe || 0}`}>
              <Chart options={totalSafeIPChart.options} series={totalSafeIPChart.series} type="line" width="100%" height={200} />
            </LegacyCard>
          </Columns>
          <div className="mt-16">
            <LegacyCard title={`Total country: ${chartAnalytics.data?.data?.totalCountry || 0}`}>
              <HeatMap />
            </LegacyCard>
          </div>
          <div className="mt-16">
            <TableVisitorLog />
          </div>
        </AnalyticsStyled>
      )}
      <Modal open={active} title="Demo" onClose={handleOpenModal} fullScreen={true} src={images.visitorScreen} />
    </Layout>
  );
}

export default Analytics;
