/* eslint-disable react-hooks/exhaustive-deps */
import { config } from '@/config';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { AlphaCard, Button, Frame, LegacyStack, Loading, Text } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StepOne from './components/StepOne';
import StepThree from './components/StepThree';
import StepTwo from './components/StepTwo';
import { welcomeSlice, welcomeStepSelector } from '@/redux/slice/welcome.slice';
import { WelcomeStyled } from './styled';

const stepItems = [
  {
    key: 1,
    title: 'Welcome',
    content: <StepOne />,
  },
  {
    key: 2,
    title: 'First rule',
    content: <StepTwo />,
  },
  {
    key: 3,
    title: 'Finish',
    content: <StepThree />,
  },
];

export const welcomeContext = React.createContext<{ isChecked: boolean; setIsChecked: any }>({
  isChecked: false,
  setIsChecked: () => {},
});

const Welcome = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const step = useSelector(welcomeStepSelector);
  const settings = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const [updateWelcomeStep, { isLoading }] = apiCaller.useUpdateWelcomeStepMutation();
  const handleChangeStep = (step: number) => () => {
    if (settings?.data) {
      if (step > settings.data.settings.welcomeStep) {
        updateWelcomeStep(step).then(() => {
          dispatch(welcomeSlice.actions.handleChangeWelcomeStep(step));
        });
      } else {
        dispatch(welcomeSlice.actions.handleChangeWelcomeStep(step));
      }
    }
  };
  const handleSkip = () => {
    updateWelcomeStep(4).then(() => {
      dispatch(welcomeSlice.actions.handleChangeWelcomeStep(4));
    });
  };
  useEffect(() => {
    if (step > 3) {
      navigate(PATH.DASHBOARD);
    }
  }, [step]);

  return (
    <welcomeContext.Provider value={{ isChecked, setIsChecked }}>
      {settings.isLoading ? (
        <Frame>
          <Loading />
        </Frame>
      ) : null}
      <WelcomeStyled>
        <AlphaCard>
          <div className="welcome-container">
            <ul>
              <div className="welcome-bridge" />
              {stepItems.map((item) => {
                return (
                  <li
                    key={item.key}
                    onClick={handleChangeStep(item.key)}
                    className={step > item.key ? 'completed' : step === item.key ? 'active' : ''}
                  >
                    <div className="step-container" data-toggle="tab" aria-expanded="false">
                      <div className="step-number">{item.key}</div>
                      <Text as="h3" variant="headingMd">
                        {item.title}
                      </Text>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content d-block">
              <div className="tab-panel">{stepItems.find((item) => item.key === step)?.content}</div>
            </div>
            <div className="button-center mt-20">
              {step === 1 ? (
                <LegacyStack alignment="center" vertical spacing="tight">
                  <Button loading={isLoading} size="medium" onClick={handleChangeStep(step + 1)} primary>
                    Get Started
                  </Button>
                  <Button plain onClick={handleSkip}>
                    Skip
                  </Button>
                </LegacyStack>
              ) : null}
              {step === 2 ? (
                <div>
                  <Button plain onClick={handleSkip}>
                    Skip
                  </Button>
                </div>
              ) : null}
              {step === 3 && (
                <Button onClick={handleChangeStep(step + 1)} size="medium" primary>
                  Finish
                </Button>
              )}
            </div>
          </div>
        </AlphaCard>
      </WelcomeStyled>
    </welcomeContext.Provider>
  );
};
export default Welcome;
