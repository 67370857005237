import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IParamsApi } from '@/types/api/params.api';
import { getSomeDaysAgo } from '@/helpers';
import { Enum } from '@/constants';

const now = new Date();
// Define the initial state using that type
const initialState: { blockHistoryTable: IParamsApi.IGetAddressBlock; inputSearch: string, chartAnalytics: IParamsApi.IGetChart, titleBtnDatePicker: string } = {
  blockHistoryTable: {
    search: '',
    status: '',
    risk: '',
    type: '',
    startDate: getSomeDaysAgo(7),
    endDate: now,
    perPage: '10',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'lastVisitedTime',
  },
  chartAnalytics: {
    startDate: getSomeDaysAgo(7),
    endDate: now,
  },
  inputSearch: '',
  titleBtnDatePicker: 'Last 7 days',
};

export const dashboardSlice = createSlice({
  name: 'blockHistory',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleBlockHistoryTable: (state, action: PayloadAction<IParamsApi.IGetAddressBlock>) => {
      state.blockHistoryTable = action.payload;
    },
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    chartAnalytics: (state, action: PayloadAction<IParamsApi.IGetChart>) => {
      state.chartAnalytics = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const blockHistorySelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.blockHistoryTable,
);
export const inputSearchSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.inputSearch,
);
export const chartBlockSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.chartAnalytics,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.titleBtnDatePicker,
);

export default dashboardSlice;
