import { colors, Enum } from '@/constants';
import { apiCaller } from '@/redux/query';
import { blockListSlice } from '@/redux/slice/blockList.slice';
import { blackListTableSelector, inputSearchBlackListSelector } from '@/redux/slice/blockList.slice';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import {
  Button,
  ChoiceList,
  EmptyState,
  Filters,
  Icon,
  Pagination,
  ResourceItem,
  ResourceList,
  Text,
  Link,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor, SortAscendingMajor, SortDescendingMajor, SortMinor } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleToastMuatation, renderCountry } from '@/helpers';
import toastSlice from '../../../redux/slice/toast.slice';
import options, { perPageOptions } from '@/constants/options';
import Swal from 'sweetalert2';
import ModalEditRule from './ModalEditRule';
import slice from '@/redux/slice';

const headerTable: IResponseApi.SettingItem = {
  id: 0,
  type: '0',
  criteria: '0',
  linkRedirect: '',
  priority: '0',
  createdAt: new Date(),
  note: '',
  city: '',
  country: '',
  ipAddress: '',
};
const configWidthColTable = {
  blockOrRedirect: '180px',
  criteria: '180px',
  description: '400px',
  action: '150px',
};

const handleIconSort = (direction: string) => {
  if (direction === 'ASC') {
    return SortAscendingMajor;
  }
  return SortDescendingMajor;
};

const TableBlackList = (): JSX.Element => {
  const dispatch = useDispatch();
  const [modalEdit, setModalEdit] = useState<{
    id: number;
    open: boolean;
    priority: string;
    actionType: string;
    criteria: string;
    value: {
      ipaddress: string;
      ipAddressStartWith: string;
      country: string;
      city: string;
      ispCode: string;
      shortUrl: string;
      linkRedirect: string;
    };
  }>({
    id: -1,
    open: false,
    priority: Enum.ListType.BlackList,
    actionType: Enum.ActionType.Block,
    criteria: Enum.CriteriaType.IpAddressStartWith,
    value: {
      ipaddress: '',
      ipAddressStartWith: '',
      country: '',
      city: '',
      ispCode: '',
      linkRedirect: '',
      shortUrl: '',
    },
  });
  const blackListTable = useSelector(blackListTableSelector);
  const inputSearchBlackList = useSelector(inputSearchBlackListSelector);
  const [state, setState] = useState({
    showToast: false,
    message: '',
    itemSelected: -1,
    activeModalDeleteAll: false,
  });
  const { data, isFetching } = apiCaller.useFetchSettingListQuery({
    ...blackListTable,
  });
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();
  const [deleteAllItem, deleteAllItemStatus] = apiCaller.useDeleteAllBlackListSettingMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearch = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleBlackListTable(value));
    }, 1000),
    [],
  );
  const emptyStateMarkup = (
    <EmptyState heading="No Item" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
  );

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  const updateSort = (pattern: any) => {
    dispatch(
      blockListSlice.actions.handleBlackListTable({
        ...blackListTable,
        sortBy: pattern,
        sort: blackListTable.sort === Enum.SortType.DESC ? Enum.SortType.ASC : Enum.SortType.DESC,
        page: 1,
      }),
    );
  };
  const handleDelete = (id: number) => () => {
    Swal.fire({
      title: 'Do you want to delete this rule?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.danger,
      cancelButtonColor: colors.primary,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        setState({ ...state, itemSelected: id });
        deleteItem(id).then((res) => {
          dispatch(slice.toastSlice.actions.handleToast(handleToastMuatation(res)));
        });
      }
    });
  };
  const handleDeleteAll = () => {
    Swal.fire({
      title: 'Do you want to delete all rule?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.danger,
      cancelButtonColor: colors.primary,
      confirmButtonText: 'Delete all',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAllItem(undefined);
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Rule deleted',
            error: false,
          }),
        );
      }
    });
  };

  const handleModalEdit = (item: IResponseApi.SettingItem) => () => {
    setModalEdit({
      open: true,
      actionType: item.type,
      criteria: item.criteria,
      id: item.id,
      priority: item.priority,
      value: {
        city: item.city,
        country: item.country,
        ipaddress: item.ipAddress,
        ipAddressStartWith: item.ipAddress,
        ispCode: item.ispCode || '',
        linkRedirect: item.linkRedirect,
        shortUrl: item.shortUrl || '',
      },
    });
  };

  const clearModalEdit = () => {
    setModalEdit({
      id: -1,
      open: false,
      priority: Enum.ListType.BlackList,
      actionType: Enum.ActionType.Block,
      criteria: Enum.CriteriaType.IpAddressStartWith,
      value: {
        ipaddress: '',
        ipAddressStartWith: '',
        country: '',
        city: '',
        ispCode: '',
        linkRedirect: '',
        shortUrl: '',
      },
    });
  };

  const filters = [
    {
      key: 'type',
      label: 'Type',
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.blockTypeOptions]}
          selected={[blackListTable.type || '']}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                type: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'criteria',
      label: 'Criteria',
      filter: (
        <ChoiceList
          title="Criteria"
          titleHidden
          choices={[{ label: 'All', value: '' }, ...options.criteriaFilters]}
          selected={[blackListTable.criteria]}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                criteria: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[blackListTable.perPage]}
          onChange={(value) =>
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                perPage: value[0],
                page: 1,
              }),
            )
          }
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            type: '',
            page: 1,
          }),
        ),
      key: 'type',
      label: `Type: ${options.blockTypeOptions.find((item) => item.value === blackListTable.type)?.label || 'All'}`,
    },
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            criteria: '',
            page: 1,
          }),
        ),
      key: 'criteria',
      label: `Criteria: ${options.criteriaFilters.find((option) => option.value === blackListTable.criteria)?.label || 'All'}`,
    },
    {
      onRemove: () =>
        dispatch(
          blockListSlice.actions.handleBlackListTable({
            ...blackListTable,
            perPage: '10',
            page: 1,
          }),
        ),
      key: 'perPage',
      label: 'Record per page: ' + blackListTable.perPage,
    },
  ];

  const filterControl = (
    <Filters
      appliedFilters={appliedFilters}
      queryValue={inputSearchBlackList}
      filters={filters}
      onQueryChange={(value) => {
        const phoneRegEx = new RegExp('^[a-zA-Z0-9_.#.-]*$');
        if (!phoneRegEx.test(value)) {
          setState({
            ...state,
            showToast: true,
            message: 'Search content is not in the correct format',
          });
        } else {
          dispatch(blockListSlice.actions.handleInputSearchBlackList(value));
          debounceSetSearch({
            ...blackListTable,
            page: 1,
            search: value,
          });
        }
      }}
      onQueryClear={() => {
        dispatch(blockListSlice.actions.handleInputSearchBlackList(''));
        debounceSetSearch({
          ...blackListTable,
          page: 1,
          search: '',
        });
      }}
      queryPlaceholder="Search by IP, country code, state/province"
      onClearAll={() => {}}
    >
      <Button destructive plain onClick={handleDeleteAll} loading={deleteAllItemStatus.isLoading}>
        Delete all
      </Button>
    </Filters>
  );

  const RenderItem = (blockItem: IResponseApi.SettingItem): JSX.Element => {
    return (
      <ResourceItem onClick={() => {}} id={blockItem.id.toString()}>
        {blockItem.id === 0 ? (
          <div className="table-content header d-flex">
            <div style={{ width: configWidthColTable.blockOrRedirect }} className="d-flex" onClick={() => updateSort('type')}>
              <Text as="h6" variant="headingMd">
                Block or Redirect
              </Text>
              <Icon
                source={
                  // eslint-disable-next-line no-nested-ternary
                  blackListTable.sortBy === 'type' ? handleIconSort(blackListTable.sort) : SortMinor
                }
                color={blackListTable.sortBy === 'type' ? 'primary' : 'base'}
              />
            </div>

            <div style={{ width: configWidthColTable.criteria }} className="d-flex" onClick={() => updateSort('criteria')}>
              <Text as="h6" variant="headingMd">
                Criteria
              </Text>
              <Icon
                source={
                  // eslint-disable-next-line no-nested-ternary
                  blackListTable.sortBy === 'criteria' ? handleIconSort(blackListTable.sort) : SortMinor
                }
                color={blackListTable.sortBy === 'criteria' ? 'primary' : 'base'}
              />
            </div>

            <div style={{ paddingRight: '8px', width: configWidthColTable.description }} className="d-flex">
              <Text as="h6" variant="headingMd">
                Description
              </Text>
            </div>

            <div style={{ width: configWidthColTable.action, marginLeft: '8px' }}>
              <Text as="h6" variant="headingMd">
                Action
              </Text>
            </div>
          </div>
        ) : (
          <div className="table-content d-flex">
            <div style={{ width: configWidthColTable.blockOrRedirect }}>
              <Text as="h6" variant="bodyMd">
                {options.blockTypeOptions.find((item) => item.value === blockItem.type)?.label}
              </Text>
            </div>
            <div style={{ width: configWidthColTable.criteria }}>
              <Text as="h6" variant="bodyMd">
                {options.criteriaFilters.find((item) => item.value === blockItem.criteria)?.label}
              </Text>
            </div>
            <div style={{ paddingRight: '8px', width: configWidthColTable.description, wordBreak: 'break-word' }}>
              {blockItem.type === Enum.ActionType.Block ? (
                <>
                  {blockItem.criteria === Enum.CriteriaType.IpAddress ||
                  blockItem.criteria === Enum.CriteriaType.IpAddressStartWith ? (
                    <Text truncate={true} as="h6" variant="bodyMd">
                      {blockItem.ipAddress}
                    </Text>
                  ) : null}
                  {blockItem.criteria === Enum.CriteriaType.Country
                    ? `${renderCountry(blockItem.country)} (${blockItem.country})`
                    : null}
                  {blockItem.criteria === Enum.CriteriaType.Province
                    ? `${renderCountry(blockItem.country)} (${blockItem.country}) - ${blockItem.city} `
                    : null}
                  {blockItem.criteria === Enum.CriteriaType.ISP ? (
                    <Text as="h6" variant="bodyMd">
                      {`${blockItem.ispName} - ${renderCountry(blockItem.country)} (${blockItem.country})`}
                    </Text>
                  ) : null}
                </>
              ) : null}
              {blockItem.type === Enum.ActionType.Redirect ? (
                <>
                  {blockItem.shortUrl ? (
                    <>
                      {blockItem.criteria === Enum.CriteriaType.IpAddress ||
                      blockItem.criteria === Enum.CriteriaType.IpAddressStartWith ? (
                        <div className="d-flex align-center">
                          <div className="mr-4">{`${blockItem.ipAddress} to`}</div>
                          <Link url={blockItem.linkRedirect} removeUnderline>
                            {blockItem.shortUrl}
                          </Link>
                        </div>
                      ) : null}
                      {blockItem.criteria === Enum.CriteriaType.Country ? (
                        <div className="d-flex align-center">
                          <div className="mr-4">{`${renderCountry(blockItem.country)} (${blockItem.country}) to `}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.shortUrl}
                          </Link>
                        </div>
                      ) : null}
                      {blockItem.criteria === Enum.CriteriaType.Province ? (
                        <div className="d-flex align-center">
                          <div className="mr-4">{`${renderCountry(blockItem.country)} - ${blockItem.city} (${
                            blockItem.country
                          }) to`}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.shortUrl}
                          </Link>
                        </div>
                      ) : null}
                      {blockItem.criteria === Enum.CriteriaType.ISP ? (
                        <div className="d-flex align-center">
                          <div className="mr-4">{`${blockItem.ispName} - ${renderCountry(blockItem.country)} (${
                            blockItem.country
                          }) to`}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.shortUrl}
                          </Link>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {blockItem.criteria === Enum.CriteriaType.IpAddress ||
                      blockItem.criteria === Enum.CriteriaType.IpAddressStartWith ? (
                        <div className="break-word">
                          <div className="mr-4">{`${blockItem.ipAddress} to`}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.linkRedirect}
                          </Link>
                        </div>
                      ) : null}
                      {blockItem.criteria === Enum.CriteriaType.Country ? (
                        <div className="break-word">
                          <div className="mr-4">{`${renderCountry(blockItem.country)} (${blockItem.country}) to `}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.linkRedirect}
                          </Link>
                        </div>
                      ) : null}
                      {blockItem.criteria === Enum.CriteriaType.Province ? (
                        <div className="break-word">
                          <div className="mr-4">{`${renderCountry(blockItem.country)} (${blockItem.country}) - ${
                            blockItem.city
                          } to`}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.linkRedirect}
                          </Link>
                        </div>
                      ) : null}
                      {blockItem.criteria === Enum.CriteriaType.ISP ? (
                        <div className="break-word">
                          <div className="mr-4">{`${blockItem.ispName} - ${renderCountry(blockItem.country)} (${
                            blockItem.country
                          }) to`}</div>
                          <Link url={blockItem.linkRedirect} external removeUnderline>
                            {blockItem.linkRedirect}
                          </Link>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
            </div>
            <div className="d-flex" style={{ width: configWidthColTable.action }}>
              <div className="control-btn edit-btn">
                <Button icon={EditMinor} onClick={handleModalEdit(blockItem)} />
              </div>
              <div className="control-btn remove-btn ml-8">
                <Button
                  loading={deleteItemStatus.isLoading && blockItem.id === state.itemSelected}
                  disabled={deleteItemStatus.isLoading && blockItem.id !== state.itemSelected}
                  icon={DeleteMinor}
                  onClick={handleDelete(blockItem.id)}
                />
              </div>
            </div>
          </div>
        )}
      </ResourceItem>
    );
  };

  return (
    <div>
      <ResourceList
        resourceName={resourceName}
        items={data && data.settingList.length > 0 ? [headerTable, ...data.settingList] : []}
        renderItem={RenderItem}
        loading={isFetching}
        filterControl={filterControl}
        emptyState={emptyStateMarkup}
      />
      <div className="mt-16">
        <Pagination
          label={
            data?.meta.totalItems
              ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.perPage) + 1} to ${Math.min(
                  data.meta.currentPage * Number(data.meta.perPage),
                  data?.meta.totalItems,
                )} of ${data?.meta.totalItems} items`
              : null
          }
          hasPrevious={!isFetching && data && data.meta.currentPage > 1}
          onPrevious={() => {
            if (data) {
              dispatch(
                blockListSlice.actions.handleBlackListTable({
                  ...blackListTable,
                  page: data && data.meta.currentPage - 1,
                }),
              );
            }
          }}
          hasNext={!isFetching && data && data.meta.currentPage < Math.ceil(data.meta.totalItems / Number(data.meta.perPage))}
          onNext={() => {
            if (data) {
              dispatch(
                blockListSlice.actions.handleBlackListTable({
                  ...blackListTable,
                  page: data.meta.currentPage + 1,
                }),
              );
            }
          }}
        />
      </div>
      <ModalEditRule
        onClose={clearModalEdit}
        open={modalEdit.open}
        actionType={modalEdit.actionType}
        criteria={modalEdit.criteria}
        id={modalEdit.id}
        priority={modalEdit.priority}
        value={{
          city: modalEdit.value.city,
          country: modalEdit.value.country,
          address: modalEdit.value.ipaddress,
          ipAddressStartWith: modalEdit.value.ipAddressStartWith,
          ispCode: modalEdit.value.ispCode,
          linkRedirect: modalEdit.value.linkRedirect,
          shortUrl: modalEdit.value.shortUrl,
        }}
      />
    </div>
  );
};

export default TableBlackList;
