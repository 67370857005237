enum ListType {
  BlackList = '1',
  WhiteList = '2',
}

enum ActionType {
  Block = '1',
  Redirect = '2',
}

export enum CriteriaType {
  IpAddress = '1',
  IpAddressStartWith = '4',
  Country = '3',
  Province = '2',
  ISP = '5',
}

enum BackgroundType {
  Color = '1',
  Image = '2',
}

enum RiskType {
  Safe = 'safe',
  Risky = 'risky',
  Dangerous = 'dangerous',
}

enum RiskLevel {
  Safe = 34,
  Risky = 66,
}

enum StatusVisitorsLogType {
  Allow = 'allow',
  Block = 'block',
}

enum TypeVisitorsLogType {
  Other = 'Other',
  Residential = 'Residential',
  Wireless = 'Wireless',
  Business = 'Business',
  VPN = 'VPN',
  TOR = 'TOR',
}

export enum UserPlan {
  FREE = 'free',
  BASIC = 'basic',
  ADVANCE = 'advance',
  PREMIUM = 'premium',
}

export enum MediaScreen {
  LARGE = 1440,
  MEDIUM = 1040,
  SMALL = 767,
  EXTRA_SMALL = 489,
}

export enum SortType {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum ReviewType {
  ACCEPT = 'accept',
  REJECT = 'reject',
}
export enum DeviceType {
  DESKTOP = 'desktop',
  SMARTPHONE = 'smartphone',
}

export default {
  ListType,
  ActionType,
  CriteriaType,
  BackgroundType,
  RiskType,
  RiskLevel,
  StatusVisitorsLogType,
  TypeVisitorsLogType,
  UserPlan,
  MediaScreen,
  SortType,
  ReviewType,
  DeviceType,
};
