/* eslint-disable react-hooks/exhaustive-deps */
import Card from '@/components/card';
import CustomDatePicker from '@/components/datePicker/DatePicker';
import CustomLayout from '@/components/layout';
import { config } from '@/config';
import { apiCaller } from '@/redux/query';
import { PATH } from '@/constants/path';
import slice from '@/redux/slice';
import { blockHistorySelector, chartBlockSelector, titleBtnDatePickerSelector } from '@/redux/slice/dashboard.slice';
import { IPropsChart } from '@/types/components';
import { Button, Columns, LegacyStack, Loading } from '@shopify/polaris';
import { RefreshMajor } from '@shopify/polaris-icons';
import { memo, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import HeatMap from './component/heatMap';
import TableBlockedHistory from './component/table';
import { DashboardStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import welcomeSlice, { welcomeStepSelector } from '@/redux/slice/welcome.slice';

const SecondaryAction = (): JSX.Element => {
  const blockHistoryTable = useSelector(blockHistorySelector);
  const chartData = useSelector(chartBlockSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const dispatch = useDispatch();
  const [fetchVisitorBlockList, fetchVisitorStatus] = apiCaller.useLazyFetchVisitorBlockListQuery();
  const [fetchChart, fetchChartStatus] = apiCaller.useLazyFetchChartBlockQuery();
  const handleRefreshClick = async () => {
    await Promise.all([
      fetchVisitorBlockList({
        page: blockHistoryTable.page,
        perPage: blockHistoryTable.perPage[0],
        sort: blockHistoryTable.sort,
      }),
      fetchChart({
        startDate: chartData.startDate,
        endDate: chartData.endDate,
      }),
    ]);
  };
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(slice.dashboardSlice.actions.handleBlockHistoryTable({ ...blockHistoryTable, startDate, endDate }));
    dispatch(slice.dashboardSlice.actions.chartAnalytics({ ...chartData, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.dashboardSlice.actions.handleTitleBtnDatePicker(title));
  };
  return (
    <LegacyStack>
      <Button
        onClick={handleRefreshClick}
        loading={fetchVisitorStatus.isLoading && fetchChartStatus.isLoading}
        icon={RefreshMajor}
      >
        Refresh
      </Button>
      <CustomDatePicker
        titleButton={titleBtn}
        setTitleButton={onSaveTitleBtnDatePicker}
        startDate={blockHistoryTable.startDate}
        endDate={blockHistoryTable.endDate}
        onSave={onSaveDatePicker}
      />
    </LegacyStack>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const step = useSelector(welcomeStepSelector);
  const chartData = useSelector(chartBlockSelector);
  const [trigger] = apiCaller.useLazyGetGeneralDetailQuery();
  const account = apiCaller.useGetGeneralDetailQuery(config?.instanceId);
  const { data } = apiCaller.useFetchChartBlockQuery({ ...chartData });
  const totalIPBlock = data?.data?.totalIpsBlockedChart?.data || [];
  const totalVPNBlock = data?.data?.totalProxyVPNChart?.data || [];
  const totalIPBlockChart: IPropsChart = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: data?.data?.totalIpsBlockedChart?.labels,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
    },

    series: [
      {
        name: 'Total Block',
        data: totalIPBlock,
      },
    ],
  };

  const totalVPNBlockChart: IPropsChart = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: data?.data?.totalProxyVPNChart?.labels,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
    },

    series: [
      {
        name: 'Total Block',
        data: totalVPNBlock,
      },
    ],
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (step < 4) {
      navigate(PATH.WELCOME);
    } else {
      navigate(PATH.DASHBOARD);
    }
  }, [step]);
  useEffect(() => {
    if (step < 4) {
      const fetchData = async () => {
        trigger(config.instanceId).then((res) => {
          dispatch(welcomeSlice.actions.handleChangeWelcomeStep(res.data?.settings.welcomeStep));
        });
      };
      fetchData();
    }
  }, []);
  return (
    <CustomLayout
      layoutProps={{
        title: 'Blockify',
        secondaryActions: <SecondaryAction />,
      }}
      isVisibleHeader={account.data?.url ? false : true}
    >
      {account?.isLoading ? (
        <Loading />
      ) : (
        <DashboardStyled>
          <Columns columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap={{ lg: '4', xl: '4', md: '4', sm: '4', xs: '4' }}>
            <Card title="Total IPs block">
              <Chart
                options={totalIPBlockChart.options}
                series={totalIPBlockChart.series}
                type="line"
                width="100%"
                height={250}
              />
            </Card>
            <Card title="Total Proxy & VPN Blocks ">
              <Chart
                options={totalVPNBlockChart.options}
                series={totalVPNBlockChart.series}
                type="line"
                width="100%"
                height={250}
              />
            </Card>
          </Columns>
          <div className="mt-16">
            <Card title="Blocked IPs heatmap">
              <HeatMap />
            </Card>
          </div>
          <div className="mt-16">
            <TableBlockedHistory />
          </div>
        </DashboardStyled>
      )}
    </CustomLayout>
  );
};
export default memo(Dashboard);
