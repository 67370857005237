import { Badge, Tooltip, Button } from "@shopify/polaris"
import { ISettingProtection } from '@/types/components'
import { memo } from "react"
import { CustomSettingToggleStyled } from "./styled"

const SettingProtection = ({ ...props }: ISettingProtection.IProps) => {
  return (
    <CustomSettingToggleStyled>
      <div style={{ maxWidth: '500px' }}>
        <strong style={{ display: 'block', marginBottom: '16px' }}>
          {props.title}
          <Badge progress='complete' status={props.enabled ? 'success' : 'critical'}>
            {props.enabled ? 'On' : 'Off'}
          </Badge>
        </strong>
        <div>
          {props.content}
        </div>
      </div>
      {props.disable ?
        <Tooltip
          content={
            props.disable ? (
              <p>
                Available on {props.plan} plan or above!
              </p>
            ) : null
          }
        >
          <Button
            onClick={props.handleProtection}
            disabled={props.disable}
            loading={props.loading}
            primary={props.enabled}
          >
            {props.enabled ? 'Turn off' : 'Turn on'}
          </Button>
        </Tooltip>
        :
        <Button
          onClick={props.handleProtection}
          disabled={props.disable}
          loading={props.loading}
          primary={props.enabled}
        >
          {props.enabled ? 'Turn off' : 'Turn on'}
        </Button>
      }
    </CustomSettingToggleStyled>
  )
}

export default memo(SettingProtection)