import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IParamsApi } from '@/types/api/params.api';
import { Enum } from '@/constants';

interface IItemBlock {
  id?: number;
  criteria: string;
  linkRedirect?: string;
  shortUrl?: string;
  priority: string;
  country: Array<string>;
  city: Array<string>;
  ipAddress: string;
  note: string;
  ispName?: Array<string>;
  ispCode?: Array<string>;
}
interface State {
  blackListTable: IParamsApi.IGetSettingList;
  whiteListTable: IParamsApi.IGetSettingList;
  inputSearchBlackList: string;
  inputSearchWhiteList: string;
  setting: {
    itemSelected: IItemBlock;
    isOpenModalSetting: boolean;
  };
  inputIsp: string;
  selectedRule: string;
  tabSelected: number;
}

// Define the initial state using that type
const initialState: State = {
  tabSelected: 0,
  blackListTable: {
    search: '',
    type: '',
    criteria: '',
    perPage: '10',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'type',
    priority: Enum.ListType.BlackList,
  },
  whiteListTable: {
    priority: Enum.ListType.WhiteList,
    search: '',
    criteria: '',
    perPage: '10',
    sort: Enum.SortType.DESC,
    sortBy: 'createdAt',
    page: 1,
  },
  inputSearchBlackList: '',
  inputSearchWhiteList: '',
  inputIsp: '',
  selectedRule: Enum.ActionType.Block,
  setting: {
    itemSelected: {
      criteria: Enum.CriteriaType.IpAddress,
      id: undefined,
      linkRedirect: '',
      shortUrl: '',
      note: '',
      priority: Enum.ListType.BlackList,
      city: [],
      country: [],
      ipAddress: '',
      ispName: [],
      ispCode: [],
    },
    isOpenModalSetting: false,
  },
};

export const blockListSlice = createSlice({
  name: 'blockList',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTabSelected: (state, action: PayloadAction<number>) => {
      state.tabSelected = action.payload;
    },
    handleBlackListTable: (state, action: PayloadAction<IParamsApi.IGetSettingList>) => {
      state.blackListTable = action.payload;
    },
    handleWhiteListTable: (state, action: PayloadAction<IParamsApi.IGetSettingList>) => {
      state.whiteListTable = action.payload;
    },
    handleInputSearchWhiteList: (state, action: PayloadAction<string>) => {
      state.inputSearchWhiteList = action.payload;
    },
    handleInputSearchBlackList: (state, action: PayloadAction<string>) => {
      state.inputSearchBlackList = action.payload;
    },
    handleInputIsp: (state, action: PayloadAction<string>) => {
      state.inputIsp = action.payload;
    },
    handleSelectedRule: (state, action: PayloadAction<string>) => {
      state.selectedRule = action.payload;
    },
    clearSetting: (state) => {
      state.setting = {
        itemSelected: {
          criteria: Enum.CriteriaType.IpAddress,
          id: undefined,
          linkRedirect: '',
          shortUrl: '',
          note: '',
          priority: Enum.ListType.BlackList,
          city: [],
          country: [],
          ipAddress: '',
          ispName: [],
          ispCode: [],
        },
        isOpenModalSetting: false,
      };
      state.inputIsp = '';
    },
    clearInputIsp: (state) => {
      state.inputIsp = '';
    },
    handleSetting: (
      state,
      action: PayloadAction<{
        itemSelected: IItemBlock;
        isOpenModalSetting: boolean;
      }>,
    ) => {
      state.setting = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const blackListTableSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.blackListTable,
);
export const tabSelectedSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.tabSelected,
);
export const settingSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.setting,
);

export const whiteListTableSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.whiteListTable,
);
export const inputSearchBlackListSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputSearchBlackList,
);
export const inputSearchWhiteListSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputSearchWhiteList,
);

export const inputIspSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.inputIsp,
);

export const selectTypeSelector = createSelector(
  (state: RootState) => state.blockList,
  (state) => state.selectedRule,
);

export default blockListSlice;
