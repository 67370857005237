const getUrlParams = (key: string) => {
  const params = new URLSearchParams(window.location.search);
  const result = params.get(key);
  return result || undefined;
};

export const config = {
  instance: getUrlParams('instance'),
  instanceId: getUrlParams('instanceId'),
};
