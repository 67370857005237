import { memo, useState } from 'react';
import { apiCaller } from '@/redux/query';
import { config } from '@/config';
import { useDispatch } from 'react-redux';
import toastSlice from '@/redux/slice/toast.slice';
import { Enum } from '@/constants';
import { handleToastMuatation } from '@/helpers';
import { LegacyCard } from '@shopify/polaris';
import { CardContentProtectionStyled } from '../../styled';
import { IParamsApi } from '@/types/api/params.api';
import SettingProtection from '../settingProtection';

const ContentProtection = () => {
  const { data, isFetching } = apiCaller.useGetGeneralDetailQuery(config.instanceId);
  const [activeContentProtection, contentProtectionStatus] = apiCaller.useActiveContentProtectionMutation();
  const [activeBehavior] = apiCaller.useActiveBehaviorMutation();
  const [disableRightClickLoading, setDisableRightClickLoading] = useState(false);
  const [disableKeyboardShortcutLoading, setDisableKeyboardShortcutLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState('');
  const dispatch = useDispatch();
  const handleProtectText = () => {
    const textProtection: IParamsApi.IContentProtection = {
      protectContents: !data?.settings.protectContents,
    };
    activeContentProtection(textProtection).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
    });
    setButtonClick('1')
  };
  const disableRightClick = () => {
    const rightClickProtection: IParamsApi.IContentProtection = {
      disableRightClick: !data?.settings.disableRightClick,
    };
    setDisableRightClickLoading(true);
    activeBehavior(rightClickProtection).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
      setDisableRightClickLoading(false);
    });
    setButtonClick('2')
  };
  const disableKeyboardShortcut = () => {
    const keywordShotcutProtection: IParamsApi.IContentProtection = {
      disableKeyboardShortcut: !data?.settings.disableKeyboardShortcut,
    };
    setDisableKeyboardShortcutLoading(true);
    activeBehavior(keywordShotcutProtection).then((res) => {
      if ('data' in res && res.data.state === 0) {
        dispatch(toastSlice.actions.handleToast(handleToastMuatation(res)));
      } else {
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      }
      setDisableKeyboardShortcutLoading(false);
    });
    setButtonClick('3')
  };

  return (
    <LegacyCard>
      {data ? (
        <CardContentProtectionStyled>
          <SettingProtection
            title="Protect contents"
            content="Prevent competitors from selecting your content (texts and images)."
            enabled={data.settings.protectContents}
            plan={Enum.UserPlan.BASIC}
            loading={contentProtectionStatus.isLoading || (isFetching && buttonClick === '1')}
            handleProtection={handleProtectText}
            disable={data.settings.user.plan === Enum.UserPlan.FREE}
          />
          <SettingProtection
            title="Disable right click"
            content="Prevent competitors from copying by disabling right click."
            enabled={data.settings.disableRightClick}
            plan={Enum.UserPlan.ADVANCE}
            loading={disableRightClickLoading || (isFetching && buttonClick === '2')}
            handleProtection={disableRightClick}
            disable={data.settings.user.plan === Enum.UserPlan.FREE || data.settings.user.plan === Enum.UserPlan.BASIC}
          />
          <SettingProtection
            title="Disable keyboard shortcut"
            content="Prevent competitors from copying by disabling keyboard shortcut."
            enabled={data.settings.disableKeyboardShortcut}
            plan={Enum.UserPlan.ADVANCE}
            loading={disableKeyboardShortcutLoading || (isFetching && buttonClick === '3')}
            handleProtection={disableKeyboardShortcut}
            disable={data.settings.user.plan === Enum.UserPlan.FREE || data.settings.user.plan === Enum.UserPlan.BASIC}
          />
        </CardContentProtectionStyled>
      ) : null}
    </LegacyCard>
  );
};
export default memo(ContentProtection);
