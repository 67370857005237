import { memo } from 'react';
import { SettingsStyled } from './styled';
import CustomLayout from '@/components/layout';
import { DescriptionList } from '@shopify/polaris';
import AccountPlanSetting from './components/accountPlan';
import CustomTemplate from './components/customTemplate';
import ContentProtection from './components/contentProtection';
import BlockerAndRedirector from './components/BlockerAndRedirector';
import GeneralSettings from './components/GeneralSettings';
const Settings = (): JSX.Element => {
  return (
    <SettingsStyled>
      <CustomLayout
        layoutProps={{
          title: 'Settings',
        }}
      >
        <DescriptionList
          items={[
            {
              term: 'Account Plan',
              description: <AccountPlanSetting />,
            },
            {
              term: 'General settings',
              description: <GeneralSettings />,
            },
            {
              term: 'Blocker & Redirector ',
              description: <BlockerAndRedirector />,
            },
            {
              term: 'Custom blocking template',
              description: <CustomTemplate />,
            },
            {
              term: 'Content Protection',
              description: <ContentProtection />,
            },
          ]}
        />
      </CustomLayout>
    </SettingsStyled>
  );
};

export default memo(Settings);
